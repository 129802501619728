import React, { useContext, useState, useEffect } from "react";
import NavbarWithCTAButton from "../components/Nav";
import {
  Card,
  Spinner,
  Table,
  Button,
  Modal,
  TextInput,
  Pagination,
  Select,
} from "flowbite-react";
import { Store } from "../context/store";
import SideBar from "../components/Sidebar";
import { IoMdAddCircleOutline, IoIosSearch } from "react-icons/io";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import apiRequest from "../utils/apiRequest";
import { HiOutlineExclamationCircle } from "react-icons/hi";

function Customers() {
  let store = useContext(Store);
  let [baseUrl] = store.url;
  let [msg, setMsg] = useState("");
  let [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = parseInt(searchParams.get("page")) || 1;
  let [clients, setClients] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [openModalOut, setOpenModalOut] = useState(false);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(page);
  const [search, setSearch] = useState("");
  let [error, setError] = useState("");
  let [errorColor, setErrorColor] = useState("");
  const [clientDetails, setClientDetails] = useState({
    phone: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    gender: "",
    address: "",
  });
  let [loading3, setLoading3] = useState(false);

  function onCloseModal() {
    setOpenModal(false);
  }

  useEffect(() => {
    Promise.all([loadClients()])
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [page]);

  const onPageChange = (page) => {
    setSearchParams({ page: page });
    setCurrentPage(page);
  };

  const loadClients = async (a) => {
    setLoading(true);
    try {
      let token = localStorage.getItem("royal-token");
      let url;
      if (a) {
        url = `${baseUrl}/clients?search=${a}`;
      } else {
        url = `${baseUrl}/clients?page=${page}`;
      }
      const { data } = await apiRequest(url, "GET", null, token);
      setClients(data.clients);
      setTotalPages(data.totalPages);
    } catch (error) {
      const unauthorizedErrors = [
        "Access Denied: Invalid or expired token. Please login again",
        "Access Denied: Please log in.",
        "Access Denied: Invalid Token.",
        "Access Denied: Invalid or expired token.",
        "Access Denied: Invalid or Expired Token.",
        "Access Denied: No token provided.",
      ];
      if (unauthorizedErrors.includes(error.message)) {
        setMsg("Session Expired!");
        setOpenModalOut(true);
        localStorage.removeItem("royal-token");
      } else {
        console.log(error);
      }
    }
  };

  let logOut = () => {
    navigate("/");
  };

  const searchClient = async (a) => {
    if (!a) return;
    await loadClients(a);
    setSearch("");
    setLoading(false);
  };

  const resetSearchClient = async () => {
    await loadClients();
    setSearch("");
    setLoading(false);
  };

  const registerClient = async (a, b) => {
    if (
      !clientDetails.confirmPassword ||
      !clientDetails.password ||
      !clientDetails.phone ||
      !clientDetails.firstName ||
      !clientDetails.lastName
    ) {
      setErrorColor("red");
      setError("Please fill all missing fields.");
      return;
    }
    if (!validatePassword(clientDetails.password)) {
      setErrorColor("red");
      setError(
        "Password: Minimun of 8 characters, including uppercase, lowercase, numbers, and special characters."
      );
      return;
    }
    if (clientDetails.confirmPassword !== clientDetails.password) {
      setErrorColor("red");
      setError("Password and Confirm Password do not match.");
      return;
    }
    const confirmed = window.confirm(
      "Are you sure you want to add a new client?"
    );
    if (confirmed) {
      setLoading3(true);
      try {
        let token = localStorage.getItem("royal-token");
        const url = `${baseUrl}/client`;
        await apiRequest(url, "POST", clientDetails, token);
        setErrorColor("green");
        setError("Successfully registered.");
        setLoading3(false);
        setClientDetails({
          phone: "",
          password: "",
          confirmPassword: "",
          firstName: "",
          lastName: "",
          gender: "",
          address: "",
        });
        setError("");
        onCloseModal();
        loadClients();
        setLoading(false);
      } catch (error) {
        const unauthorizedErrors = [
          "Access Denied: Invalid or expired token. Please login again",
          "Access Denied: Please log in.",
          "Access Denied: Invalid Token.",
          "Access Denied: Invalid or expired token.",
          "Access Denied: Invalid or Expired Token.",
          "Access Denied: No token provided.",
        ];
        if (unauthorizedErrors.includes(error.message)) {
          setMsg("Session Expired!");
          setOpenModalOut(true);
          localStorage.removeItem("royal-token");
        } else {
          setErrorColor("red");
          setError(error.message);
          setLoading3(false);
          console.log(error);
        }
      }
    }
  };

  return (
    <>
      <title>Clients</title>
      <meta
        name="description"
        content="Customers Page to the Royalmines Property Office"
      />
      <div className="container mx-auto">
        <NavbarWithCTAButton />
        {/* {loading && (
          <div className="flex justify-center items-center h-[80vh]">
            <Spinner color="info" aria-label="info spinner example" size="xl" />
          </div>
        )} */}

        {/* {!loading && ( */}
          <div className="container-fluid mx-auto">
            <div className="grid gap-4 grid-cols-6 md:grid-cols-5 mt-5">
              <SideBar customers="actives" />
              <div className="col-span-5 md:col-span-4" data-aos="zoom-in">
                <div className="mb-4">
                  <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                    Clients
                  </h5>
                  <div className="flex flex-wrap gap-2 mt-5 justify-between">
                    <div className="max-w-md flex">
                      <TextInput
                        id="search4"
                        type="text"
                        placeholder="Search by name, phone or email"
                        className="w-fit me-3"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <Button
                        className="me-2"
                        color="dark"
                        onClick={() => searchClient(search)}
                      >
                        <IoIosSearch className="me-2" />
                        Search
                      </Button>
                      <Button onClick={() => resetSearchClient()}>
                        <IoIosSearch className="me-2" />
                        Reset
                      </Button>
                    </div>
                    <Button color="success" onClick={() => setOpenModal(true)}>
                      <IoMdAddCircleOutline className="me-2" />
                      New Client
                    </Button>
                  </div>
                </div>
                {!loading ? (
                  <>
                    <Card className="rounded-xl my-4">
                      <div className="overflow-x-auto overflow-y-scroll h-[50vh] scrollbar-always-visible">
                        <Table hoverable>
                          <Table.Head>
                            <Table.HeadCell>Name</Table.HeadCell>
                            <Table.HeadCell>Phone</Table.HeadCell>
                            <Table.HeadCell>Address</Table.HeadCell>
                            <Table.HeadCell>Gender</Table.HeadCell>
                            <Table.HeadCell>
                              <span className="sr-only">Edit</span>
                            </Table.HeadCell>
                          </Table.Head>

                          <Table.Body className="divide-y">
                            {clients.length === 0 ? (
                              <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                <Table.Cell
                                  colSpan={5}
                                  className="text-center py-10"
                                >
                                  No clients added yet.
                                </Table.Cell>
                              </Table.Row>
                            ) : (
                              clients.map((e, i) => (
                                <Table.Row
                                  key={i}
                                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                >
                                  <Table.Cell
                                    className="whitespace-nowrap font-medium text-gray-900 dark:text-white"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {e?.firstName} {e?.lastName}
                                  </Table.Cell>
                                  <Table.Cell className="text-ellipsis">
                                    {e.phone}
                                  </Table.Cell>
                                  <Table.Cell className="text-ellipsis capitalize">
                                    {e.address}
                                  </Table.Cell>
                                  <Table.Cell className="text-ellipsis">
                                    {e.gender}
                                  </Table.Cell>
                                  <Table.Cell>
                                    <Link
                                      to={`/client/${e.id}`}
                                      className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                                      style={{
                                        textDecoration: "none",
                                      }}
                                    >
                                      View
                                    </Link>
                                  </Table.Cell>
                                </Table.Row>
                              ))
                            )}
                          </Table.Body>
                        </Table>
                      </div>
                    </Card>

                    {clients?.length > 0 && (
                      <div className="flex overflow-x-auto sm:justify-center mb-5">
                        <Pagination
                          currentPage={currentPage}
                          totalPages={totalPages}
                          onPageChange={onPageChange}
                          showIcons
                          previousLabel=""
                          nextLabel=""
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="flex justify-center items-center h-[100%]">
                    <Spinner
                      style={{ color: "#0078e7" }}
                      aria-label="info spinner example"
                      size="xl"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        {/* // )} */}
      </div>

      {/* modal for add client */}
      <Modal show={openModal} size="md" onClose={onCloseModal} popup>
        <Modal.Header />
        <Modal.Body>
          <div className="space-y-6">
            <h3 className="text-xl font-medium text-gray-900 dark:text-white text-center">
              Add New Client
            </h3>
            <div className="flex justify-center">
              <small
                style={{
                  color: errorColor,
                  textAlign: "center",
                }}
              >
                {error}
              </small>
            </div>
            <div>
              <TextInput
                id="firstName"
                placeholder="First Name"
                value={clientDetails.firstName}
                onChange={(event) =>
                  setClientDetails({
                    ...clientDetails,
                    firstName: event.target.value,
                  })
                }
                required
              />
            </div>
            <div>
              <TextInput
                id="lastName"
                placeholder="Last Name"
                value={clientDetails.lastName}
                onChange={(event) =>
                  setClientDetails({
                    ...clientDetails,
                    lastName: event.target.value,
                  })
                }
                required
              />
            </div>
            <div>
              <TextInput
                id="tel"
                placeholder="Phone Number"
                value={clientDetails.phone}
                onChange={(event) =>
                  setClientDetails({
                    ...clientDetails,
                    phone: event.target.value,
                  })
                }
                type="tel"
                required
              />
            </div>
            <div>
              <TextInput
                id="password"
                type="password"
                placeholder="Password"
                value={clientDetails.password}
                onChange={(event) =>
                  setClientDetails({
                    ...clientDetails,
                    password: event.target.value,
                  })
                }
                required
              />
            </div>
            <div>
              <TextInput
                id="password1"
                type="password"
                placeholder="Confirm Password"
                value={clientDetails.confirmPassword}
                onChange={(event) =>
                  setClientDetails({
                    ...clientDetails,
                    confirmPassword: event.target.value,
                  })
                }
                required
              />
            </div>
            <div>
              <TextInput
                id="address"
                type="text"
                placeholder="Address"
                value={clientDetails.address}
                onChange={(event) =>
                  setClientDetails({
                    ...clientDetails,
                    address: event.target.value,
                  })
                }
                required
              />
            </div>
            <div>
              <Select
                id="gender"
                onChange={(event) =>
                  setClientDetails({
                    ...clientDetails,
                    gender: event.target.value,
                  })
                }
                required
                style={{ textTransform: "capitalize" }}
              >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </Select>
            </div>
            <div className="w-full flex justify-center">
              <Button disabled={loading3} onClick={() => registerClient()}>
                {loading3 ? "Loading..." : "Register"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* modal for logout */}
      <Modal show={openModalOut} size="md" popup>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              {msg}
            </h3>
            <div className="flex justify-center gap-4">
              <Button color="failure" onClick={() => logOut()}>
                {"OK"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Customers;

function validatePassword(password) {
  const re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{8,}$/;
  return re.test(password);
}
