import React, { useContext, useState } from "react";
import { Button, Card, Label, TextInput } from "flowbite-react";
import { Store } from "../context/store";
import { useNavigate } from "react-router-dom";
import img from "../assets/images/logo1.png";
import apiRequest from "../utils/apiRequest";

function Login(props) {
  let store = useContext(Store);
  let [baseUrl] = store.url;
  let [password, setPassword] = useState("");
  let [email, setEmail] = useState("");
  let [error, setError] = useState("");
  const navigate = useNavigate();
  let [loading, setLoading] = useState(false);

  const login = async () => {
    if (!email || !password) {
      setError("Email and Password is required");
      return;
    }
    setLoading(true);
    try {
      const url = `${baseUrl}/login`;
      const body = { email, password };
      const { data, message } = await apiRequest(url, "POST", body, null);
      setError(message);
      localStorage.setItem("royal-token", data);
      setEmail("");
      setPassword("");
      navigate("/appointments");
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <title>Offcie Login</title>
      <meta name="description" content="Login Page to the Office" />
      <div className="login">
        <div className="container mx-auto">
          <div className="toast bg-[#fafafa]">
            <img src={img} className="h-14 " alt="Royalmines Logo" />
          </div>
          <div className="toast">{props.error}</div>
          <div className="flex justify-center items-center h-screen">
            <Card className="w-3/4 md:w-1/2 lg:w-1/4 card">
              <h3 className="text-center font-extrabold text-2xl">Welcome</h3>
              <p className="text-red-500 text-sm text-center">{error}</p>
              <div className="flex max-w-md flex-col gap-4">
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="username1" value="Your email" />
                  </div>
                  <TextInput
                    id="username1"
                    type="text"
                    placeholder="Enter Email"
                    required
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="password1" value="Your password" />
                  </div>
                  <TextInput
                    id="password1"
                    type="password"
                    required
                    placeholder="Enter Password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <Button
                  disabled={loading}
                  className="bg-#6eb5ff"
                  onClick={() => login()}
                >
                  {loading ? "Loading..." : "Sign In"}
                </Button>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
