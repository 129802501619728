import React, { useContext, useState, useEffect } from "react";
import NavbarWithCTAButton from "../components/Nav";
import {
  Card,
  Spinner,
  Table,
  Button,
  Modal,
  TextInput,
  Select,
  Pagination,
  FileInput,
  Label,
} from "flowbite-react";
import { Store } from "../context/store";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import SideBar from "../components/Sidebar";
import { IoMdAddCircleOutline, IoIosSearch } from "react-icons/io";
import apiRequest from "../utils/apiRequest";
import { HiOutlineExclamationCircle } from "react-icons/hi";

function Projects() {
  let store = useContext(Store);
  let [baseUrl] = store.url;
  let [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  let [propertyData, setProperties] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [totalPages, setTotalPages] = useState(1);
  const page = parseInt(searchParams.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(page);
  const [openModalOut, setOpenModalOut] = useState(false);
  let [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  let [error, setError] = useState("");
  let [errorColor, setErrorColor] = useState("");
  const [propertyDetails, setPropertyDetails] = useState({
    title: "",
    price: null,
    featuresString: "",
    videos: "",
    description: "",
    quantity: null,
    propertyType: "",
    propertySize: "",
    sittingRoom: null,
    bedrooms: null,
    toilet: null,
    bathroom: null,
    documentType: "",
    status: "",
    landmarksString: "",
    address: "",
    dining: "",
    images: [],
    plans: [],
  });
  const [propertyDetailsEdit, setPropertyDetailsEdit] = useState({
    id: "",
    title: "",
    price: null,
    featuresString: "",
    videos: "",
    description: "",
    quantity: null,
    propertyType: "",
    propertySize: "",
    sittingRoom: null,
    bedrooms: null,
    toilet: null,
    bathroom: null,
    documentType: "",
    status: "",
    landmarksString: "",
    address: "",
    dining: "",
    images: [],
    plans: [],
  });
  let [loading3, setLoading3] = useState(false);

  useEffect(() => {
    Promise.all([loadProperties()])
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [page]);

  const loadProperties = async (a) => {
    setLoading(true);
    try {
      let url;
      if (a) {
        url = `${baseUrl}/propertiess?search=${a}`;
      } else {
        url = `${baseUrl}/propertiess?page=${page}`;
      }
      const token = localStorage.getItem("royal-token");
      const { data } = await apiRequest(url, "GET", null, token);
      setProperties(data.properties);
      setTotalPages(data.totalPages);
    } catch (error) {
      const unauthorizedErrors = [
        "Access Denied: Invalid or expired token. Please login again",
        "Access Denied: Please log in.",
        "Access Denied: Invalid Token.",
        "Access Denied: Invalid or expired token.",
        "Access Denied: Invalid or Expired Token.",
        "Access Denied: No token provided.",
      ];
      if (unauthorizedErrors.includes(error.message)) {
        setMsg("Session Expired!");
        setOpenModalOut(true);
        localStorage.removeItem("royal-token");
      } else {
        console.log(error);
      }
    }
  };

  const onPageChange = (page) => {
    setSearchParams({ page: page });
    setCurrentPage(page);
  };

  function onCloseModal() {
    setOpenModal(false);
    setError("");
  }

  function onCloseModalEdit() {
    setOpenModalEdit(false);
    setError("");
    setPropertyDetailsEdit({
      title: "",
      price: null,
      featuresString: "",
      videos: "",
      description: "",
      quantity: null,
      propertyType: "",
      propertySize: "",
      sittingRoom: null,
      bedrooms: null,
      toilet: null,
      bathroom: null,
      documentType: "",
      status: "",
      landmarksString: "",
      address: "",
      dining: "",
      images: [],
      plans: [],
    });
  }

  const searchProperties = async (a) => {
    if (!a) return;
    await loadProperties(a);
    setSearch("");
    setLoading(false);
  };

  const resetSearchProperties = async () => {
    await loadProperties();
    setSearch("");
    setLoading(false);
  };

  const handleChange = (event) => {
    const { name, value, files } = event.target;

    if (files) {
      const selectedImages = Array.from(files).slice(0, 4);
      setPropertyDetails((prevDetails) => ({
        ...prevDetails,
        [name]: selectedImages, // Update for file uploads (images)
      }));
    } else {
      // Handle regular input changes (text, number, etc.)
      setPropertyDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value, // Update based on the value
      }));
    }
  };

  const handleChangeEdit = (event) => {
    const { name, value, files } = event.target;

    if (files) {
      const selectedImages = Array.from(files).slice(0, 4);
      setPropertyDetailsEdit((prevDetails) => ({
        ...prevDetails,
        [name]: selectedImages, // Update for file uploads (images)
      }));
    } else {
      // Handle regular input changes (text, number, etc.)
      setPropertyDetailsEdit((prevDetails) => ({
        ...prevDetails,
        [name]: value, // Update based on the value
      }));
    }
  };

  const createProperty = async () => {
    if (
      !propertyDetails.title ||
      !propertyDetails.address ||
      !propertyDetails.price
    ) {
      setErrorColor("red");
      setError("Please fill all missing fields.");
      return;
    }

    const confirmed = window.confirm(
      "Are you sure you want to add a new property?"
    );
    if (!confirmed) return;

    setLoading3(true);

    try {
      const formData = new FormData(); // Create a FormData object
      formData.append("title", propertyDetails.title);
      formData.append("price", propertyDetails.price);
      formData.append("address", propertyDetails.address);
      formData.append("featuresString", propertyDetails.featuresString);
      formData.append("quantity", propertyDetails.quantity);
      formData.append("propertyType", propertyDetails.propertyType);
      formData.append("propertySize", propertyDetails.propertySize);
      formData.append("sittingRoom", propertyDetails.sittingRoom);
      formData.append("bedrooms", propertyDetails.bedrooms);
      formData.append("toilet", propertyDetails.toilet);
      formData.append("bathroom", propertyDetails.bathroom);
      formData.append("documentType", propertyDetails.documentType);
      formData.append("status", propertyDetails.status);
      formData.append("landmarksString", propertyDetails.landmarksString);
      formData.append("videos", propertyDetails.videos);
      formData.append("dining", propertyDetails.dining);

      for (const image of propertyDetails.images) {
        formData.append("images", image);
      }
      for (const plan of propertyDetails.plans) {
        formData.append("plans", plan);
      }
      const token = localStorage.getItem("royal-token");
      const url = `${baseUrl}/property`;
      await apiRequest(url, "POST", formData, token);

      setErrorColor("green");
      setError("Successfully added.");
      setPropertyDetails({
        title: "",
        price: null,
        featuresString: "",
        videos: "",
        description: "",
        quantity: null,
        propertyType: "",
        propertySize: "",
        sittingRoom: null,
        bedrooms: null,
        toilet: null,
        bathroom: null,
        documentType: "",
        status: "",
        landmarksString: "",
        address: "",
        dining: "",
        images: [],
        plans: [],
      });
      onCloseModal();
      loadProperties();
      setLoading3(false);
      setLoading(false);
    } catch (error) {
      const unauthorizedErrors = [
        "Access Denied: Invalid or expired token. Please login again",
        "Access Denied: Please log in.",
        "Access Denied: Invalid Token.",
        "Access Denied: Invalid or expired token.",
        "Access Denied: Invalid or Expired Token.",
        "Access Denied: No token provided.",
      ];
      if (unauthorizedErrors.includes(error.message)) {
        setMsg("Session Expired!");
        setOpenModalOut(true);
        localStorage.removeItem("royal-token");
      } else {
        setErrorColor("red");
        setError(error.message);
        setLoading3(false);
        console.log(error);
      }
    }
  };

  const updateProperty = async () => {
    if (
      !propertyDetailsEdit.title ||
      !propertyDetailsEdit.address ||
      !propertyDetailsEdit.price
    ) {
      setErrorColor("red");
      setError("Please fill all missing fields.");
      return;
    }

    const confirmed = window.confirm(
      "Are you sure you want to update this property?"
    );
    if (!confirmed) return;

    setLoading3(true);

    try {
      const formData = new FormData(); // Create a FormData object
      formData.append("title", propertyDetailsEdit.title);
      formData.append("price", propertyDetailsEdit.price);
      formData.append("address", propertyDetailsEdit.address);
      formData.append("featuresString", propertyDetailsEdit.featuresString);
      formData.append("quantity", propertyDetailsEdit.quantity);
      formData.append("propertyType", propertyDetailsEdit.propertyType);
      formData.append("propertySize", propertyDetailsEdit.propertySize);
      formData.append("sittingRoom", propertyDetailsEdit.sittingRoom);
      formData.append("bedrooms", propertyDetailsEdit.bedrooms);
      formData.append("toilet", propertyDetailsEdit.toilet);
      formData.append("bathroom", propertyDetailsEdit.bathroom);
      formData.append("documentType", propertyDetailsEdit.documentType);
      formData.append("status", propertyDetailsEdit.status);
      formData.append("landmarksString", propertyDetailsEdit.landmarksString);
      formData.append("videos", propertyDetailsEdit.videos);
      formData.append("dining", propertyDetailsEdit.dining);

      for (const image of propertyDetailsEdit.images) {
        formData.append("images", image);
      }
      for (const plan of propertyDetailsEdit.plans) {
        formData.append("plans", plan);
      }
      const token = localStorage.getItem("royal-token");
      const url = `${baseUrl}/property/${propertyDetailsEdit.id}`;
      await apiRequest(url, "PUT", formData, token);

      setErrorColor("green");
      setError("Successfully Updated.");
      setPropertyDetailsEdit({
        title: "",
        price: null,
        featuresString: "",
        videos: "",
        description: "",
        quantity: null,
        propertyType: "",
        propertySize: "",
        sittingRoom: null,
        bedrooms: null,
        toilet: null,
        bathroom: null,
        documentType: "",
        status: "",
        landmarksString: "",
        address: "",
        dining: "",
        images: [],
        plans: [],
      });
      onCloseModalEdit();
      loadProperties();
      setLoading3(false);
      setLoading(false);
    } catch (error) {
      const unauthorizedErrors = [
        "Access Denied: Invalid or expired token. Please login again",
        "Access Denied: Please log in.",
        "Access Denied: Invalid Token.",
        "Access Denied: Invalid or expired token.",
        "Access Denied: Invalid or Expired Token.",
        "Access Denied: No token provided.",
      ];
      if (unauthorizedErrors.includes(error.message)) {
        setMsg("Session Expired!");
        setOpenModalOut(true);
        localStorage.removeItem("royal-token");
      } else {
        setErrorColor("red");
        setError(error.message);
        setLoading3(false);
        console.log(error);
      }
    }
  };

  let logOut = () => {
    navigate("/");
  };

  let edit = (e) => {
    setOpenModalEdit(true);
    setPropertyDetailsEdit({
      id: e.id,
      title: e.title,
      price: +e.price,
      featuresString: e.features?.join(", "),
      videos: e.videos,
      description: "",
      quantity: e.quantity,
      propertyType: e.propertyType,
      propertySize: e.propertySize,
      sittingRoom: e.sittingRoom,
      bedrooms: e.bedrooms,
      toilet: e.toilet,
      bathroom: e.bathroom,
      documentType: e.documentType,
      status: e.status,
      landmarksString: e.landmarks?.join(", "),
      address: e.address,
      dining: e.dining,
      images: e.images,
      plans: e.plans,
    });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-[100vh]">
        <Spinner
          style={{ color: "#0078e7" }}
          aria-label="info spinner example"
          size="xl"
        />
      </div>
    );
  }

  return (
    <>
      <title>Properties</title>
      <meta
        name="description"
        content="Project Page to the Royalmines Property Office"
      />
      <div className="container mx-auto">
        <NavbarWithCTAButton />

        {!loading && (
          <div className="container-fluid mx-auto">
            <div className="grid gap-4 grid-cols-6 md:grid-cols-5 mt-5">
              <SideBar projects="actives" />
              <div className="col-span-5 md:col-span-4" data-aos="slide-left">
                <div className="mb-4">
                  <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                    Properties
                  </h5>
                  <div className="flex flex-wrap gap-2 mt-5 justify-between">
                    <div className="max-w-md flex">
                      <TextInput
                        id="search4"
                        type="text"
                        placeholder="Search by property title..."
                        className="w-fit me-3"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <Button
                        className="me-2"
                        color="dark"
                        onClick={() => searchProperties(search)}
                      >
                        <IoIosSearch className="me-2" />
                        Search
                      </Button>
                      <Button onClick={() => resetSearchProperties()}>
                        <IoIosSearch className="me-2" />
                        Reset
                      </Button>
                    </div>
                    <Button color="success" onClick={() => setOpenModal(true)}>
                      <IoMdAddCircleOutline className="me-2" />
                      Add Property
                    </Button>
                  </div>
                </div>
                <Card className="rounded-xl my-4">
                  <div className="overflow-x-auto overflow-y-scroll h-[50vh] scrollbar-always-visible">
                    <Table hoverable>
                      <Table.Head>
                        <Table.HeadCell>Title</Table.HeadCell>
                        <Table.HeadCell>Price</Table.HeadCell>
                        <Table.HeadCell>Quantity</Table.HeadCell>
                        <Table.HeadCell>Status</Table.HeadCell>
                        <Table.HeadCell>
                          <span className="sr-only">Edit</span>
                        </Table.HeadCell>
                        <Table.HeadCell>
                          <span className="sr-only">Edit</span>
                        </Table.HeadCell>
                      </Table.Head>

                      <Table.Body className="divide-y">
                        {propertyData.length === 0 ? (
                          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                            <Table.Cell
                              colSpan={4}
                              className="text-center py-4"
                            >
                              No properties available.
                            </Table.Cell>
                          </Table.Row>
                        ) : (
                          propertyData?.map((e, i) => (
                            <Table.Row
                              key={i}
                              className={`bg-white dark:border-gray-700 dark:bg-gray-800`}
                            >
                              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                {e.title}
                              </Table.Cell>
                              <Table.Cell className="text-ellipsis">
                                {e.price.toLocaleString()}
                              </Table.Cell>
                              <Table.Cell className="text-ellipsis">
                                {e.quantity}
                              </Table.Cell>
                              <Table.Cell className="text-ellipsis">
                                Now Selling
                              </Table.Cell>
                              <Table.Cell>
                                <Button
                                  className="bg-[#000]"
                                  onClick={() => edit(e)}
                                >
                                  Edit
                                </Button>
                              </Table.Cell>
                              <Table.Cell>
                                <Link
                                  className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                                  to={`/property/${e.id}`}
                                >
                                  View
                                </Link>
                              </Table.Cell>
                            </Table.Row>
                          ))
                        )}
                      </Table.Body>
                    </Table>
                  </div>
                </Card>

                {propertyData?.length > 0 && (
                  <div className="flex overflow-x-auto sm:justify-center mb-5">
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={onPageChange}
                      showIcons
                      previousLabel=""
                      nextLabel=""
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* modal for new Project */}
      <Modal show={openModal} size="md" onClose={onCloseModal} popup>
        <Modal.Header />
        <Modal.Body>
          <div className="space-y-6">
            <h3 className="text-xl font-medium text-gray-900 dark:text-white text-center">
              Add New Property
            </h3>
            <div className="flex justify-center">
              <small
                style={{
                  color: errorColor,
                  textAlign: "center",
                }}
              >
                {error}
              </small>
            </div>
            <div>
              <TextInput
                id="title"
                placeholder="Property Title"
                name="title"
                value={propertyDetails.title}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <TextInput
                id="price"
                placeholder="Price"
                type="Number"
                name="price"
                value={propertyDetails.price}
                onChange={handleChange}
                min={1}
                required
              />
            </div>
            <div>
              <TextInput
                id="address"
                placeholder="Address"
                name="address"
                value={propertyDetails.address}
                onChange={handleChange}
                type="text"
                required
              />
            </div>
            <div>
              <TextInput
                id="featuresString"
                type="text"
                placeholder="Features"
                name="featuresString"
                value={propertyDetails.featuresString}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <TextInput
                id="password1"
                type="text"
                placeholder="Youtube Video URL"
                name="videos"
                value={propertyDetails.videos}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <Label for="multiple-file-upload">Finished Building:</Label>
              <FileInput
                id="multiple-file-upload"
                name="images"
                onChange={handleChange}
                multiple
                max={4}
              />
              {propertyDetails.images.length > 0 && (
                <p>Selected Images: {propertyDetails.images.length} (max 4)</p>
              )}
            </div>
            <div>
              <Label for="multiple-file-upload2">Plans:</Label>
              <FileInput
                id="multiple-file-upload2"
                name="plans"
                onChange={handleChange}
                multiple
                max={4}
              />
              {propertyDetails.plans.length > 0 && (
                <p>Selected Images: {propertyDetails.plans.length} (max 4)</p>
              )}
            </div>
            <div>
              <TextInput
                id="quantity"
                placeholder="Quantity"
                type="Number"
                name="quantity"
                value={propertyDetails.quantity}
                onChange={handleChange}
                min={1}
                required
              />
            </div>
            <div>
              <TextInput
                id="propertyType"
                placeholder="Property Type"
                name="propertyType"
                value={propertyDetails.propertyType}
                onChange={handleChange}
                type="text"
                required
              />
            </div>
            <div>
              <TextInput
                id="propertySize"
                placeholder="Property Size eg 250sqm"
                name="propertySize"
                value={propertyDetails.propertySize}
                onChange={handleChange}
                type="text"
                required
              />
            </div>
            <div>
              <TextInput
                id="sittingRoom"
                placeholder="Number of Sitting Room"
                name="sittingRoom"
                value={propertyDetails.sittingRoom}
                onChange={handleChange}
                type="text"
                required
              />
            </div>
            <div>
              <TextInput
                id="bedrooms"
                placeholder="Number of Bedrooms"
                name="bedrooms"
                value={propertyDetails.bedrooms}
                onChange={handleChange}
                type="text"
                required
              />
            </div>
            <div>
              <TextInput
                id="toilet"
                placeholder="Number of Toilets"
                name="toilet"
                value={propertyDetails.toilet}
                onChange={handleChange}
                type="text"
                required
              />
            </div>
            <div>
              <TextInput
                id="bathroom"
                placeholder="Number of Bathroom"
                name="bathroom"
                value={propertyDetails.bathroom}
                onChange={handleChange}
                type="text"
                required
              />
            </div>
            <div>
              <TextInput
                id="documentType"
                placeholder="Document Type eg C of O"
                name="documentType"
                value={propertyDetails.documentType}
                onChange={handleChange}
                type="text"
                required
              />
            </div>
            <div>
              <TextInput
                id="landmarksString"
                placeholder="Landmarks"
                name="landmarksString"
                value={propertyDetails.landmarksString}
                onChange={handleChange}
                type="text"
                required
              />
            </div>
            <div>
              <Select
                id="dining"
                name="dining"
                value={propertyDetails.dining}
                onChange={handleChange}
                required
                style={{ textTransform: "capitalize" }}
              >
                <option value="">Dining Area</option>
                <option value="Male">Yes</option>
                <option value="Female">No</option>
              </Select>
            </div>
            <div>
              <TextInput
                id="status"
                placeholder="Status eg Now Selling, Sold Out"
                name="status"
                value={propertyDetails.status}
                onChange={handleChange}
                type="text"
                required
              />
            </div>

            <div className="w-full flex justify-center">
              <Button disabled={loading3} onClick={() => createProperty()}>
                {loading3 ? "Loading..." : "Create"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* modal for edit Project */}
      <Modal show={openModalEdit} size="md" onClose={onCloseModalEdit} popup>
        <Modal.Header />
        <Modal.Body>
          <div className="space-y-6">
            <h3 className="text-xl font-medium text-gray-900 dark:text-white text-center">
              Edit Property
            </h3>
            <div className="flex justify-center">
              <small
                style={{
                  color: errorColor,
                  textAlign: "center",
                }}
              >
                {error}
              </small>
            </div>
            <div>
              <TextInput
                id="title"
                placeholder="Property Title"
                name="title"
                value={propertyDetailsEdit.title}
                onChange={handleChangeEdit}
                required
              />
            </div>
            <div>
              <TextInput
                id="price"
                placeholder="Price"
                type="Number"
                name="price"
                value={propertyDetailsEdit.price}
                onChange={handleChangeEdit}
                min={1}
                required
              />
            </div>
            <div>
              <TextInput
                id="address"
                placeholder="Address"
                name="address"
                value={propertyDetailsEdit.address}
                onChange={handleChangeEdit}
                type="text"
                required
              />
            </div>
            <div>
              <TextInput
                id="featuresString"
                type="text"
                placeholder="Features"
                name="featuresString"
                value={propertyDetailsEdit.featuresString}
                onChange={handleChangeEdit}
                required
              />
            </div>
            <div>
              <TextInput
                id="password1"
                type="text"
                placeholder="Youtube Video URL"
                name="videos"
                value={propertyDetailsEdit.videos}
                onChange={handleChangeEdit}
                required
              />
            </div>
            <div>
              <Label for="multiple-file-upload">Finished Building:</Label>
              <FileInput
                id="multiple-file-upload"
                name="images"
                onChange={handleChangeEdit}
                multiple
                max={4}
              />
              {propertyDetailsEdit.images.length > 0 && (
                <p>
                  Selected Images: {propertyDetailsEdit.images.length} (max 4)
                </p>
              )}
            </div>
            <div>
              <Label for="multiple-file-upload2">Plans:</Label>
              <FileInput
                id="multiple-file-upload2"
                name="plans"
                onChange={handleChangeEdit}
                multiple
                max={4}
              />
              {propertyDetailsEdit.plans.length > 0 && (
                <p>
                  Selected Images: {propertyDetailsEdit.plans.length} (max 4)
                </p>
              )}
            </div>
            <div>
              <TextInput
                id="quantity"
                placeholder="Quantity"
                type="Number"
                name="quantity"
                value={propertyDetailsEdit.quantity}
                onChange={handleChangeEdit}
                min={1}
                required
              />
            </div>
            <div>
              <TextInput
                id="propertyType"
                placeholder="Property Type"
                name="propertyType"
                value={propertyDetailsEdit.propertyType}
                onChange={handleChangeEdit}
                type="text"
                required
              />
            </div>
            <div>
              <TextInput
                id="propertySize"
                placeholder="Property Size eg 250sqm"
                name="propertySize"
                value={propertyDetailsEdit.propertySize}
                onChange={handleChangeEdit}
                type="text"
                required
              />
            </div>
            <div>
              <TextInput
                id="sittingRoom"
                placeholder="Number of Sitting Room"
                name="sittingRoom"
                value={propertyDetailsEdit.sittingRoom}
                onChange={handleChangeEdit}
                type="text"
                required
              />
            </div>
            <div>
              <TextInput
                id="bedrooms"
                placeholder="Number of Bedrooms"
                name="bedrooms"
                value={propertyDetailsEdit.bedrooms}
                onChange={handleChangeEdit}
                type="text"
                required
              />
            </div>
            <div>
              <TextInput
                id="toilet"
                placeholder="Number of Toilets"
                name="toilet"
                value={propertyDetailsEdit.toilet}
                onChange={handleChangeEdit}
                type="text"
                required
              />
            </div>
            <div>
              <TextInput
                id="bathroom"
                placeholder="Number of Bathroom"
                name="bathroom"
                value={propertyDetailsEdit.bathroom}
                onChange={handleChangeEdit}
                type="text"
                required
              />
            </div>
            <div>
              <TextInput
                id="documentType"
                placeholder="Document Type eg C of O"
                name="documentType"
                value={propertyDetailsEdit.documentType}
                onChange={handleChangeEdit}
                type="text"
                required
              />
            </div>
            <div>
              <TextInput
                id="landmarksString"
                placeholder="Landmarks"
                name="landmarksString"
                value={propertyDetailsEdit.landmarksString}
                onChange={handleChangeEdit}
                type="text"
                required
              />
            </div>
            <div>
              <Select
                id="dining"
                name="dining"
                value={propertyDetailsEdit.dining}
                onChange={handleChangeEdit}
                required
                style={{ textTransform: "capitalize" }}
              >
                <option value="">Dining Area</option>
                <option value="Male">Yes</option>
                <option value="Female">No</option>
              </Select>
            </div>
            <div>
              <TextInput
                id="status"
                placeholder="Status eg Now Selling, Sold Out"
                name="status"
                value={propertyDetailsEdit.status}
                onChange={handleChangeEdit}
                type="text"
                required
              />
            </div>

            <div className="w-full flex justify-center">
              <Button
                disabled={loading3}
                onClick={() => updateProperty(propertyDetailsEdit?.id)}
              >
                {loading3 ? "Loading..." : "Update"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* modal for logout */}
      <Modal show={openModalOut} size="md" popup>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              {msg}
            </h3>
            <div className="flex justify-center gap-4">
              <Button color="failure" onClick={() => logOut()}>
                {"OK"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Projects;
