import React, { useContext, useState, useEffect } from "react";
import NavbarWithCTAButton from "../components/Nav";
import {
  Card,
  Spinner,
  Table,
  Button,
  Modal,
  Breadcrumb,
  Tabs,
} from "flowbite-react";
import { Store } from "../context/store";
import SideBar from "../components/Sidebar";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate, Link, useParams } from "react-router-dom";
import apiRequest from "../utils/apiRequest";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import img from "../assets/images/user.png";

function SingleCustomer() {
  let store = useContext(Store);
  let [baseUrl] = store.url;
  let [msg, setMsg] = useState([]);
  let [subs, setSubs] = useState([]);
  let [loading, setLoading] = useState(false);
  let [client, setClient] = useState({});
  const [openModalOut, setOpenModalOut] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    Promise.all([loadClient(), loadClientSubs()])
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  const loadClient = async () => {
    setLoading(true);
    try {
      let token = localStorage.getItem("royal-token");
      const url = `${baseUrl}/client/${id}`;
      const { data } = await apiRequest(url, "GET", null, token);
      setClient(data);
    } catch (error) {
      const unauthorizedErrors = [
        "Access Denied: Invalid or expired token. Please login again",
        "Access Denied: Please log in.",
        "Access Denied: Invalid Token.",
        "Access Denied: Invalid or expired token.",
        "Access Denied: Invalid or Expired Token.",
        "Access Denied: No token provided.",
      ];
      if (unauthorizedErrors.includes(error.message)) {
        setMsg("Session Expired!");
        setOpenModalOut(true);
        localStorage.removeItem("royal-token");
      } else {
        console.log(error);
      }
    }
  };

  const loadClientSubs = async () => {
    setLoading(true);
    try {
      let token = localStorage.getItem("royal-token");
      const url = `${baseUrl}/client/admin/subscription/${id}`;
      const { data } = await apiRequest(url, "GET", null, token);
      setSubs(data);
    } catch (error) {
      const unauthorizedErrors = [
        "Access Denied: Invalid or expired token. Please login again",
        "Access Denied: Please log in.",
        "Access Denied: Invalid Token.",
        "Access Denied: Invalid or expired token.",
        "Access Denied: Invalid or Expired Token.",
        "Access Denied: No token provided.",
      ];
      if (unauthorizedErrors.includes(error.message)) {
        setMsg("Session Expired!");
        setOpenModalOut(true);
        localStorage.removeItem("royal-token");
      } else {
        console.log(error);
      }
    }
  };

  const respondToSub = async (a, b) => {
    const body = {
      approved: a === "approve",
      status: a === "approve" ? "in progress" : "cancelled",
    };
    const confirmed = window.confirm(`Are you sure you want to ${a}`);
    if (confirmed) {
      setLoading(true);
      try {
        let token = localStorage.getItem("royal-token");
        const url = `${baseUrl}/approve/subscription/${b}`;
        const { data } = await apiRequest(url, "PUT", body, token);
        setSubs((prevSubs) =>
          prevSubs.map((singleSub) =>
            singleSub.id === data.id ? data : singleSub
          )
        );
      } catch (error) {
        const unauthorizedErrors = [
          "Access Denied: Invalid or expired token. Please login again",
          "Access Denied: Please log in.",
          "Access Denied: Invalid Token.",
          "Access Denied: Invalid or expired token.",
          "Access Denied: Invalid or Expired Token.",
          "Access Denied: No token provided.",
        ];
        if (unauthorizedErrors.includes(error.message)) {
          setMsg("Session Expired!");
          setOpenModalOut(true);
          localStorage.removeItem("royal-token");
        } else {
          console.log(error);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const deleteSub = async (a) => {
    const confirmed = window.confirm(`Are you sure you want to delete`);
    if (confirmed) {
      setLoading(true);
      try {
        let token = localStorage.getItem("royal-token");
        const url = `${baseUrl}/subscription/${a}`;
        await apiRequest(url, "DELETE", null, token);
        loadClientSubs();
      } catch (error) {
        const unauthorizedErrors = [
          "Access Denied: Invalid or expired token. Please login again",
          "Access Denied: Please log in.",
          "Access Denied: Invalid Token.",
          "Access Denied: Invalid or expired token.",
          "Access Denied: Invalid or Expired Token.",
          "Access Denied: No token provided.",
        ];
        if (unauthorizedErrors.includes(error.message)) {
          setMsg("Session Expired!");
          setOpenModalOut(true);
          localStorage.removeItem("royal-token");
        } else {
          console.log(error);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  let logOut = () => {
    navigate("/");
  };

  return (
    <>
      <title>Clients</title>
      <meta
        name="description"
        content="Customers Page to the Royalmines Property Office"
      />
      <div className="container mx-auto">
        <NavbarWithCTAButton />
        {/* {loading && (
          <div className="flex justify-center items-center h-[80vh]">
            <Spinner color="info" aria-label="info spinner example" size="xl" />
          </div>
        )} */}

        {/* {!loading && ( */}
          <div className="container-fluid mx-auto">
            <div className="grid gap-4 grid-cols-6 md:grid-cols-5 mt-5">
              <SideBar customers="actives" />
              <div className="col-span-5 md:col-span-4" data-aos="zoom-in">
                <div className="mb-4">
                  <Breadcrumb>
                    <Link to="/clients">
                      <Breadcrumb.Item>
                        <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                          Clients
                        </h5>
                      </Breadcrumb.Item>
                    </Link>
                    <Breadcrumb.Item>
                      {client?.firstName} {client?.lastName}
                    </Breadcrumb.Item>
                  </Breadcrumb>
                  <div className="flex flex-wrap gap-2 mt-5 justify-end">
                    <Button as={Link} to="/clients" className="bg-#6eb5ff">
                      <IoMdArrowBack className="me-2" />
                      Go Back
                    </Button>
                  </div>
                </div>
                {!loading ? (
                  <>
                    <div className="">
                      <Tabs.Group
                        aria-label="Tabs with underline"
                        style="fullWidth"
                        id="tab"
                      >
                        <Tabs.Item active title="Profile">
                          <Card>
                            <div className="flex justify-around align-middle items-center pb-1 flex-wrap gap-5">
                              {client.image ? (
                                <img
                                  className="object-cover w-full md:w-1/2 lg:w-1/3 rounded-xl"
                                  src={client.image}
                                  alt={`image of ${client.firstName} ${client.lastName} `}
                                />
                              ) : (
                                <img
                                  src={img}
                                  alt={`image of ${client.firstName} ${client.lastName} `}
                                />
                              )}
                              <div className="flex flex-col">
                                <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">
                                  {client.firstName} {client.lastName}
                                </h5>
                                <span className="text-sm text-gray-500 dark:text-gray-400">
                                  <strong>Phone:</strong> {client.phone}
                                </span>
                                <span className="text-sm text-gray-500 dark:text-gray-400">
                                  <strong>Occupation:</strong>{" "}
                                  {client.occupation}
                                </span>
                                <span className="text-sm text-gray-500 dark:text-gray-400">
                                  <strong>Email:</strong> {client.email}
                                </span>
                                <span className="text-sm text-gray-500 dark:text-gray-400">
                                  <strong>Date Of Birth:</strong>{" "}
                                  {formatDate(client.dob)}
                                </span>
                                <span className="text-sm text-gray-500 dark:text-gray-400">
                                  <strong>Gender:</strong> {client.gender}
                                </span>
                                <span className="text-sm text-gray-500 dark:text-gray-400">
                                  <strong>Marital Status:</strong>{" "}
                                  {client.maritalStatus}
                                </span>
                              </div>
                            </div>

                            <div>
                              <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                                <li className="py-3 sm:py-4">
                                  <div className="flex items-center space-x-4">
                                    <div className="min-w-0 flex gap-1 flex-1">
                                      <p className=" text-sm font-medium text-gray-900 dark:text-white">
                                        Address:
                                      </p>
                                      <p className="capitalize text-sm text-gray-500 dark:text-gray-400">
                                        {client.address}
                                      </p>
                                    </div>
                                  </div>
                                </li>

                                <li className="py-3 sm:py-4">
                                  <div className="flex items-center space-x-4">
                                    <div className="min-w-0 flex gap-1 flex-1">
                                      <p className=" text-sm font-medium text-gray-900 dark:text-white">
                                        Nationality:
                                      </p>
                                      <p className="capitalize  text-sm text-gray-500 dark:text-gray-400">
                                        {client.nationality}
                                      </p>
                                    </div>
                                  </div>
                                </li>

                                <li className="py-3 sm:py-4">
                                  <div className="flex items-center space-x-4">
                                    <div className="min-w-0 flex gap-1 flex-1">
                                      <p className=" text-sm font-medium text-gray-900 dark:text-white">
                                        Next Of Kin:
                                      </p>
                                      <p className="capitalize  text-sm text-gray-500 dark:text-gray-400">
                                        {client.nextOfKin}
                                      </p>
                                    </div>
                                  </div>
                                </li>

                                <li className="py-3 sm:py-4">
                                  <div className="flex items-center space-x-4">
                                    <div className="min-w-0 flex gap-1 flex-1">
                                      <p className=" text-sm font-medium text-gray-900 dark:text-white">
                                        Next Of Kin Phone:
                                      </p>
                                      <p className="capitalize  text-sm text-gray-500 dark:text-gray-400">
                                        {client.nextOfKinPhone}
                                      </p>
                                    </div>
                                  </div>
                                </li>

                                <li className="py-3 sm:py-4">
                                  <div className="flex items-center space-x-4">
                                    <div className="min-w-0 flex gap-1">
                                      <p className=" text-sm font-medium text-gray-900 dark:text-white">
                                        Type Of Identification:
                                      </p>
                                      <p className="capitalize text-sm text-gray-500 dark:text-gray-400">
                                        {client.typeOfIdentification}
                                      </p>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>

                            <div className="flex flex-col justify-center align-middle items-center pb-1">
                              <h5 className="font-bold my-4">Identification</h5>
                              {client.identification ? (
                                <img
                                  className="object-cover w-full md:w-1/2 lg:w-1/3 rounded-xl"
                                  src={client.identification}
                                  alt={`image of ${client.firstName} ${client.lastName} `}
                                />
                              ) : (
                                <h3>No identification added yet.</h3>
                              )}
                            </div>
                          </Card>
                        </Tabs.Item>

                        <Tabs.Item title="Subscriptions">
                          <div>
                            <div className="divide-y divide-gray-200 dark:divide-gray-700">
                              {subs?.length > 0 ? (
                                subs?.map((a, b) => {
                                  return (
                                    <>
                                      <div
                                        key={b}
                                        className="grid grid-cols-1 sm:grid-cols-2 gap-4 p-3 sm:p-4 bg-slate-100 mb-3 rounded-md"
                                      >
                                        <div className="flex flex-col">
                                          <DetailRow
                                            label="Property"
                                            value={a.propertyId.title}
                                          />
                                          <DetailRow
                                            label="Address"
                                            value={a.propertyId.address}
                                          />
                                          <DetailRow
                                            label="Payment Option"
                                            value={a.paymentOption}
                                          />
                                          <DetailRow
                                            label="Request Date"
                                            value={formatDate(a.createdAt)}
                                          />
                                        </div>
                                        <div className="flex flex-col">
                                          <DetailRow
                                            label="Payment Plan"
                                            value={a.paymentPlan}
                                          />
                                          <DetailRow
                                            label="Property Type"
                                            value={a.propertyId.propertyType}
                                          />
                                          <DetailRow
                                            label="Progress"
                                            value={a.progress}
                                          />
                                          <DetailRow
                                            label="Approved"
                                            value={a.approved ? "Yes" : "No"}
                                          />
                                          <DetailRow
                                            label="Status"
                                            value={a.status}
                                          />
                                          <Actions
                                            a={a}
                                            handleAction={respondToSub}
                                            handleDelete={deleteSub}
                                          />
                                        </div>
                                      </div>
                                    </>
                                  );
                                })
                              ) : (
                                <h3 className="text-center my-5">
                                  Client has not subscribed to a property yet.
                                </h3>
                              )}
                            </div>
                          </div>
                        </Tabs.Item>
                      </Tabs.Group>
                    </div>
                  </>
                ) : (
                  <div className="flex justify-center items-center h-[100%]">
                    <Spinner
                      style={{ color: "#0078e7" }}
                      aria-label="info spinner example"
                      size="xl"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        {/* )} */}
      </div>

      {/* modal for logout */}
      <Modal show={openModalOut} size="md" popup>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              {msg}
            </h3>
            <div className="flex justify-center gap-4">
              <Button color="failure" onClick={() => logOut()}>
                {"OK"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SingleCustomer;

function formatDate(dateString) {
  const dateObj = new Date(dateString);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[dateObj.getMonth()];

  const desiredFormat = `${dateObj.getFullYear()}-${month}-${dateObj.getDate()}`;

  return desiredFormat;
}

const DetailRow = ({ label, value }) => (
  <div className="flex items-center mb-2">
    <p className="text-sm font-medium text-gray-900 dark:text-white mr-2">
      {label}:
    </p>
    <p className="capitalize text-sm text-gray-500 dark:text-gray-400">
      {value}
    </p>
  </div>
);

const Actions = ({ a, handleAction, handleDelete }) => (
  <div className="flex justify-end gap-3">
    {!a.approved && (
      <button
        className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        disabled={a.approved}
        onClick={() => handleDelete(a.id)}
      >
        Delete
      </button>
    )}
    {a.approved === false && a.status !== "cancelled" && (
      <button
        className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        onClick={() => handleAction("cancel", a.id)}
      >
        Cancel
      </button>
    )}
    {a.approved === false && a.status !== "cancelled" && (
      <button
        className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        onClick={() => handleAction("approve", a.id)}
      >
        Approve
      </button>
    )}

    {a.approved && (
      <button
        className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        onClick={() => handleAction("update", a.id)}
      >
        Update
      </button>
    )}
  </div>
);
