import React, { useContext, useState, useEffect } from "react";
import { Avatar, Dropdown, Navbar } from "flowbite-react";
import img from "../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { Store } from "../context/store";
import apiRequest from "../utils/apiRequest";

export default function NavbarWithCTAButton() {
  let store = useContext(Store);
  let [baseUrl] = store.url;
  let [loggedInUser, setLoggedInUser] = store.loggedInUser;
  const navigate = useNavigate();
  let [, setError] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    loadUser();
  }, []);
  
  const logOut = async () => {
    const confirmed = window.confirm("Are you sure you want to log out?");

    if (confirmed) {
      try {
        const url = `${baseUrl}/logout`;
        apiRequest(url, "POST", null, localStorage.getItem("royal-token"));
        localStorage.removeItem("royal-token");
        navigate("/");
      } catch (error) {
        alert(`Error: ${error.message}`);
      }
    }
  };

  const loadUser = async () => {
    try {
      const token = localStorage.getItem("royal-token");
      const url = `${baseUrl}/token`;
      const { data } = await apiRequest(url, "GET", null, token);
      setLoggedInUser(data);
      setName(`${data.firstName} ${data.lastName}`);
    } catch (error) {
      setError("An error occurred. Please try again.");
      throw error;
    }
  };
  
  return (
    <Navbar fluid rounded>
      <Navbar.Brand as={Link} to={"/appointments"}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "2px",
            marginRight: "4px",
          }}
        >
          <img
            className="me-2"
            src={img}
            alt="Royal Mines Logo"
            style={{ width: "50px", height: "50px" }}
          />
          <span
            style={{
              textTransform: "uppercase",
              color: "#0078e7",
              fontWeight: 800,
              lineHeight: "20px",
            }}
          >
            Royalmines <br /> Property
          </span>
        </div>
        {/* <img src={img} className="h-10 md:h-14" alt="Royalmines Logo" /> */}
      </Navbar.Brand>
      <div className="flex md:order-2">
        <Dropdown
          arrowIcon={false}
          inline
          label={
            <Avatar
              alt="User Icon"
              img="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnfAxGV-fZxGL9elM_hQ2tp7skLeSwMyUiwo4lMm1zyA&s"
              rounded
            />
          }
        >
          <Dropdown.Header>
            <span className="block text-sm">{name}</span>
            <span className="block truncate text-sm font-medium">{loggedInUser.email}</span>
          </Dropdown.Header>
          <Dropdown.Item as={Link} to={"/settings"}>
            Settings
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item className="cursor-pointer" onClick={logOut}>
            Sign out
          </Dropdown.Item>
        </Dropdown>
        <Navbar.Toggle />
      </div>
      {/* <Navbar.Collapse>
        <Navbar.Link as={Link} to={"/dashboard"} active>
          Home
        </Navbar.Link>
      </Navbar.Collapse> */}
    </Navbar>
  );
}
