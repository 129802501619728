const apiRequest = async (url, method, body = null, token = null) => {
  try {
    const headers = {};

    if (token) {
      headers["royal-token"] = token;
    }

    let options = {
      method,
      headers,
    };

    // Handle form data with file
    if (body instanceof FormData) {
      options.body = body;
      // 'Content-Type' header will be automatically set by the browser for FormData
    } else if (body) {
      // Handle regular JSON body (optional)
      options.body = JSON.stringify(body);
      headers["Content-Type"] = "application/json";
    }

    const response = await fetch(url, options);

    if (!response.ok) {
      const errorData = await response.json();
      const errorMsg = errorData.message || "An error occurred";
      throw new Error(errorMsg);
    }

    const data = await response.json();

    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export default apiRequest;
