import React, { useContext, useState, useEffect } from "react";
import NavbarWithCTAButton from "../components/Nav";
import {
  Card,
  Spinner,
  Button,
  Modal,
  TextInput,
  Label,
} from "flowbite-react";
import { Store } from "../context/store";
import { useNavigate } from "react-router-dom";
import SideBar from "../components/Sidebar";
import apiRequest from "../utils/apiRequest";
import { HiOutlineExclamationCircle } from "react-icons/hi";

function SiteSettings() {
  let store = useContext(Store);
  let [baseUrl] = store.url;
  let [msg, setMsg] = useState("");
  let [error, setError] = useState("");
  let [loading1, setLoading1] = useState(false);
  const [oPExLimit, setOpExLimit] = useState();
  const [openModalOut, setOpenModalOut] = useState(false);
  const navigate = useNavigate();
  let [opex, setOpex] = useState(null);

  useEffect(() => {
    Promise.all([loadSettings()])
      .then(() => setLoading1(false))
      .catch(() => setLoading1(false));
  }, []);

  const loadSettings = async () => {
    try {
      let token = localStorage.getItem("royal-token");
      const url = `${baseUrl}/settings`;
      const { data } = await apiRequest(url, "GET", null, token);
      setOpExLimit(data);
    } catch (error) {
      const unauthorizedErrors = [
        "Access Denied: Invalid or expired token. Please login again",
        "Access Denied: Please log in.",
        "Access Denied: Invalid Token.",
        "Access Denied: Invalid or expired token.",
        "Access Denied: Invalid or Expired Token.",
        "Access Denied: No token provided.",
      ];
      if (unauthorizedErrors.includes(error.message)) {
        setMsg("Session Expired!");
        setOpenModalOut(true);
        localStorage.removeItem("royal-token");
      } else {
        console.log(error);
      }
    }
  };

  const changeMaxOpex = async () => {
    const confirmed = window.confirm(
      "Are you sure you want to change Opex limit?"
    );
    if (confirmed) {
      setLoading1(true);
      try {
        let token = localStorage.getItem("royal-token");
        const url = `${baseUrl}/settings/${oPExLimit.id}`;
        const { data } = await apiRequest(
          url,
          "PUT",
          { oPExLimit: opex },
          token
        );
        setOpExLimit(data.oPExLimit);
        setError("Updated.");
        setOpex(0);
        loadSettings();
      } catch (error) {
        setError(error.message);
        console.log(error);
      } finally {
        setLoading1(false);
      }
    }
  };

  let logOut = () => {
    navigate("/");
  };

  return (
    <>
      <title>Site Settings</title>
      <meta
        name="description"
        content="Site Settings Page to the Royalmines Property Office"
      />
      <div className="container mx-auto">
        <NavbarWithCTAButton />

        <div className="container  fixed ">
          <div className="grid gap-4 grid-cols-6 md:grid-cols-5 mt-5">
            <SideBar siteSettings="actives" />
            <div className="col-span-5 md:col-span-4" data-aos="flip-up">
              <div className="mb-4">
                <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                  Site Settings
                </h5>
              </div>
              {!loading1 ? (
                <>
                  <Card className="rounded-xl my-4">
                    <h3>
                      Present Maximum OpEx:{" "}
                      <strong>
                        &#8358; {oPExLimit?.oPExLimit?.toLocaleString()}
                      </strong>
                    </h3>
                    <div className="flex justify-center items-center">
                      <Card className="w-3/4 md:w-1/2 card">
                        <p className="text-red-500 text-sm text-center">
                          {error}
                        </p>
                        <div className="flex max-w-md flex-col gap-4">
                          <div>
                            <div className="mb-2 block">
                              <Label htmlFor="opex" value="Maximum Opex" />
                            </div>
                            <TextInput
                              id="opex"
                              placeholder="Enter Max Amount for OpEx"
                              required
                              name="opex"
                              value={opex}
                              type="number"
                              onChange={(e) => setOpex(e.target.value)}
                            />
                          </div>
                          <Button
                            disabled={loading1}
                            className="bg-#6eb5ff"
                            onClick={() => changeMaxOpex()}
                          >
                            {loading1 ? "Loading..." : "Update Amount"}
                          </Button>
                        </div>
                      </Card>
                    </div>
                  </Card>
                </>
              ) : (
                <div className="flex justify-center items-center h-[100%]">
                  <Spinner
                    style={{ color: "#0078e7" }}
                    aria-label="info spinner example"
                    size="xl"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* modal for logout */}
      <Modal show={openModalOut} size="md" popup>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              {msg}
            </h3>
            <div className="flex justify-center gap-4">
              <Button color="failure" onClick={() => logOut()}>
                {"OK"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SiteSettings;
