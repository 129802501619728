import React, { useContext } from "react";
import { Card, Table, Button } from "flowbite-react";
import { Store } from "../context/store";
import { IoMdDownload, IoMdArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
import { usePDF } from "react-to-pdf";
import { useNavigate } from "react-router-dom";

let PreviewReceipts = () => {
  let store = useContext(Store);
  let [baseUrl] = store.url;
  let [invoice, setInvoice] = store.invoice;
  const totalAmount = Object.values(invoice?.rows || {}).reduce(
    (total, row) => total + (row.amount || 0),
    0
  );
  const navigate = useNavigate();

  const { toPDF, targetRef } = usePDF({
    filename: `Royalmines-${invoice.invoiceName}.pdf`,
  });
  let download = () => {
    toPDF();
    setInvoice({ rows: [] });
    navigate("/receipts");
  };
  return (
    <>
      <title>Preview Receipt</title>
      <meta
        name="description"
        content="Preview Page to the Royalmines Property Office"
      />
      <div className="container mx-auto">
        <div className="container-fluid mx-auto">
          <div data-aos="slide-left">
            <div className="flex flex-wrap gap-2 mt-5 justify-between align-middle">
              <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                Preview Receipts
              </h5>

              <Button onClick={() => download()} color="success">
                <IoMdDownload className="me-2" />
                Download Receipt
              </Button>

              <Button color="info" as={Link} to="/receipts">
                <IoMdArrowBack className="me-2" />
                Go Back
              </Button>
            </div>
            <div ref={targetRef}>
              <Card className="rounded-xl my-4 receipt">
                <div className="receipt1">
                  {/* receipt headers */}
                  <div className="flex justify-between">
                    {/* company name */}
                    <div className="font-extrabold uppercase">
                      <h3>royalmines Property limited</h3>
                      <h3>plot 251/252 herbert macaulay way,</h3>
                      <h3>millennium plaza cbd,</h3>
                      <h3>abuja.</h3>
                    </div>
                    {/* sales order */}
                    <div>
                      <h2 className="capitalize text-3xl font-extrabold">
                        sales order
                      </h2>
                      <table className="border-2 border-black rounded mt-4">
                        <tbody className="text-center">
                          <tr>
                            <td className="border-b-2 border-r-2 border-black">
                              <h3 className="font-bold">Date</h3>
                            </td>
                            <td className="border-b-2 border-black px-5">
                              <h3 className="py-2 font-bold">S.O No.</h3>
                            </td>
                          </tr>
                          <tr>
                            <td className="border-r-2 border-black px-5">
                              <p className="">{invoice?.date}</p>
                            </td>
                            <td>
                              <p className="py-2">{invoice?.serial}</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {/* name and address */}
                  <div className="flex gap-10 justify-between mt-10">
                    <div className="border-2 border-black w-1/2 p-3">
                      <h3 className="border-b-2 border-black pb-2 mb-2">
                        Name / Address
                      </h3>
                      <ul className="uppercase">
                        <li>{invoice?.invoiceName}</li>
                        {invoice?.invoiceAddress
                          ?.split(",")
                          .map((addressPart, index) => (
                            <li key={index}>{addressPart.trim()}</li>
                          ))}
                      </ul>
                    </div>

                    <div className="border-2 border-black w-1/2 p-3">
                      <h3 className="border-b-2 border-black pb-2 mb-2">
                        Ship To
                      </h3>
                      <ul className="uppercase">
                        <li>{invoice?.shipName}</li>
                        {invoice?.shipAddress
                          ?.split(",")
                          .map((addressPart, index) => (
                            <li key={index}>{addressPart.trim()}</li>
                          ))}
                      </ul>
                    </div>
                  </div>

                  {/* invoice body */}
                  <div className="flex justify-end">
                    <table className="border-2 border-black rounded mt-4">
                      <tbody className="text-center">
                        <tr>
                          <td className="border-b-2 border-r-2 border-black">
                            <h3 className="font-bold">P.O No.</h3>
                          </td>
                          <td className="border-b-2 border-black px-5">
                            <h3 className="py-2 font-bold">Project</h3>
                          </td>
                        </tr>
                        <tr>
                          <td className="border-r-2 border-black px-5">
                            <p className="min-h-10 min-w-20 pt-2">
                              {invoice?.po}
                            </p>
                          </td>
                          <td className="px-5">
                            <p className="min-h-10 min-w-20 pt-2">
                              {invoice?.project}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="overflow-x-auto">
                    <Table className="border-2 border-black text-xs">
                      <Table.Head>
                        <Table.HeadCell>Item</Table.HeadCell>
                        <Table.HeadCell colSpan={3}>Description</Table.HeadCell>
                        <Table.HeadCell>Ordered</Table.HeadCell>
                        <Table.HeadCell>Rate</Table.HeadCell>
                        <Table.HeadCell>Amount</Table.HeadCell>
                      </Table.Head>

                      <Table.Body className="divide-y divide-x text-black">
                        {invoice?.rows?.map((e, i) => {
                          return (
                            <Table.Row>
                              <Table.Cell className="whitespace-nowrap text-ellipsis">
                                {e.item}
                              </Table.Cell>
                              <Table.Cell colSpan={3}>
                                {e.description}
                              </Table.Cell>
                              <Table.Cell className="text-ellipsis">
                                {e.ordered}
                              </Table.Cell>
                              <Table.Cell>{e.rate}</Table.Cell>
                              <Table.Cell>{e.amount}</Table.Cell>
                            </Table.Row>
                          );
                        })}

                        <Table.Row className="border-none">
                          <Table.Cell className="whitespace-nowrap font-medium text-black text-ellipsis"></Table.Cell>
                          <Table.Cell
                            colSpan={3}
                            className="text-ellipsis"
                          ></Table.Cell>
                          <Table.Cell className="text-ellipsis"></Table.Cell>
                          <Table.Cell></Table.Cell>
                          <Table.Cell></Table.Cell>
                        </Table.Row>

                        <Table.Row className="border-none">
                          <Table.Cell className="whitespace-nowrap font-medium text-black text-ellipsis"></Table.Cell>
                          <Table.Cell
                            colSpan={3}
                            className="text-ellipsis"
                          ></Table.Cell>
                          <Table.Cell className="text-ellipsis"></Table.Cell>
                          <Table.Cell></Table.Cell>
                          <Table.Cell></Table.Cell>
                        </Table.Row>

                        <Table.Row className="border-none">
                          <Table.Cell className="whitespace-nowrap font-medium text-black text-ellipsis"></Table.Cell>
                          <Table.Cell
                            colSpan={3}
                            className="text-ellipsis"
                          ></Table.Cell>
                          <Table.Cell className="text-ellipsis"></Table.Cell>
                          <Table.Cell></Table.Cell>
                          <Table.Cell></Table.Cell>
                        </Table.Row>

                        <Table.Row className="border-none">
                          <Table.Cell className="whitespace-nowrap font-medium text-black text-ellipsis"></Table.Cell>
                          <Table.Cell
                            colSpan={3}
                            className="text-ellipsis"
                          ></Table.Cell>
                          <Table.Cell className="text-ellipsis"></Table.Cell>
                          <Table.Cell></Table.Cell>
                          <Table.Cell></Table.Cell>
                        </Table.Row>

                        <Table.Row className="border-none">
                          <Table.Cell className="whitespace-nowrap font-medium text-black text-ellipsis"></Table.Cell>
                          <Table.Cell
                            colSpan={3}
                            className="text-ellipsis"
                          ></Table.Cell>
                          <Table.Cell className="text-ellipsis"></Table.Cell>
                          <Table.Cell></Table.Cell>
                          <Table.Cell></Table.Cell>
                        </Table.Row>

                        <Table.Row className="border-none">
                          <Table.Cell className="whitespace-nowrap font-medium text-black text-ellipsis"></Table.Cell>
                          <Table.Cell
                            colSpan={3}
                            className="text-ellipsis"
                          ></Table.Cell>
                          <Table.Cell className="text-ellipsis"></Table.Cell>
                          <Table.Cell></Table.Cell>
                          <Table.Cell></Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell colSpan={5}></Table.Cell>
                          <Table.Cell className="font-extrabold text-2xl">
                            Total
                          </Table.Cell>
                          <Table.Cell className="font-bold text-lg">
                            &#8358;
                            {totalAmount.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviewReceipts;
