import React, { useContext, useState, useEffect } from "react";
import NavbarWithCTAButton from "../components/Nav";
import {
  Card,
  Spinner,
  Table,
  Button,
  Pagination,
  Modal,
  Dropdown,
} from "flowbite-react";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { Store } from "../context/store";
import { useNavigate, useSearchParams } from "react-router-dom";
import SideBar from "../components/Sidebar";
import apiRequest from "../utils/apiRequest";
import { FiFilter } from "react-icons/fi";

function Message() {
  let store = useContext(Store);
  let [baseUrl] = store.url;
  let [messages, setMessages] = useState([]);
  let [message, setMessage] = useState({});
  let [loading1, setLoading1] = useState(false);
  let [loading2, setLoading2] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [totalPages, setTotalPages] = useState(1);
  const page = parseInt(searchParams.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(page);
  let [status, setStatus] = useState("pending");
  let [msg, setMsg] = useState("");
  const [openModalOut, setOpenModalOut] = useState(false);
  const onPageChange = (page) => {
    setSearchParams({ page: page });
    setCurrentPage(page);
  };

  function onCloseModal() {
    setOpenModal(false);
    setMessage({});
  }

  useEffect(() => {
    Promise.all([loadMessages()])
      .then(() => setLoading1(false))
      .catch(() => setLoading1(false));
  }, [page, status]);

  const loadMessages = async () => {
    setLoading1(true);
    try {
      let token = localStorage.getItem("royal-token");
      const url = `${baseUrl}/appointments?type=message&page=${page}&status=${status}`;
      const { data } = await apiRequest(url, "GET", null, token);
      setMessages(data.appointments);
      setTotalPages(data.totalPages);
    } catch (error) {
      const unauthorizedErrors = [
        "Access Denied: Invalid or expired token. Please login again",
        "Access Denied: Please log in.",
        "Access Denied: Invalid Token.",
        "Access Denied: Invalid or expired token.",
        "Access Denied: Invalid or Expired Token.",
        "Access Denied: No token provided.",
      ];
      if (unauthorizedErrors.includes(error.message)) {
        setMsg("Session Expired!");
        setOpenModalOut(true);
        localStorage.removeItem("royal-token");
      } else {
        console.log(error);
      }
    }
  };

  const respondToMessage = async (a, b) => {
    setLoading2(true);
    try {
      let token = localStorage.getItem("royal-token");
      const url = `${baseUrl}/appointment/${a}`;
      const { data } = await apiRequest(url, "PUT", { status: b }, token);
      setMessage(data);
      setMessages((prevMessages) =>
        prevMessages.map((message) => (message.id === data.id ? data : message))
      );
      setLoading2(false);
    } catch (error) {
      setLoading2(false);
      console.log(error);
    }
  };

  let displayOnModal = (e) => {
    setMessage(e);
    setOpenModal(true);
  };

  let logOut = () => {
    navigate("/");
  };
  return (
    <>
      <title>Messages</title>
      <meta
        name="description"
        content="Messages Page to the Royalmines Property Office"
      />
      <div className="container mx-auto">
        <NavbarWithCTAButton />

        <div className="container  fixed ">
          <div className="grid gap-4 grid-cols-6 md:grid-cols-5 mt-5">
            <SideBar dashboard="actives" message="activess" />
            <div className="col-span-5 md:col-span-4" data-aos="flip-up">
              <div className="mb-4">
                <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                  Messages
                </h5>
                <div className="flex flex-wrap gap-2 mt-5 justify-end">
                  <Dropdown
                    label={
                      <Button className="bg-#6eb5ff">
                        <FiFilter className="me-2" /> Filter By
                      </Button>
                    }
                    dismissOnClick={true}
                    inline
                  >
                    <Dropdown.Item
                      className={status === "" ? "active-dropdown" : ""}
                      onClick={() => {
                        setSearchParams({ page: 1 });
                        setStatus("");
                        setCurrentPage(1);
                      }}
                    >
                      All
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={status === "pending" ? "active-dropdown" : ""}
                      onClick={() => {
                        setSearchParams({ page: 1 });
                        setStatus("pending");
                        setCurrentPage(1);
                      }}
                    >
                      Pending
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={
                        status === "completed" ? "active-dropdown" : ""
                      }
                      onClick={() => {
                        setSearchParams({ page: 1 });
                        setStatus("completed");
                        setCurrentPage(1);
                      }}
                    >
                      Completed
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={
                        status === "cancelled" ? "active-dropdown" : ""
                      }
                      onClick={() => {
                        setSearchParams({ page: 1 });
                        setStatus("cancelled");
                        setCurrentPage(1);
                      }}
                    >
                      Cancelled
                    </Dropdown.Item>
                  </Dropdown>
                </div>
              </div>
              {!loading1 ? (
                <>
                  <Card className="rounded-xl my-4">
                    <div className="overflow-x-auto overflow-y-scroll h-[45vh] scrollbar-always-visible">
                      <Table hoverable>
                        <Table.Head>
                          <Table.HeadCell>Name</Table.HeadCell>
                          <Table.HeadCell>Message</Table.HeadCell>
                          <Table.HeadCell>Phone</Table.HeadCell>
                          <Table.HeadCell>
                            <span className="sr-only">Edit</span>
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          {messages.length === 0 ? (
                            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                              <Table.Cell
                                colSpan={3}
                                className="text-center py-4"
                              >
                                No pending messages received yet.
                              </Table.Cell>
                            </Table.Row>
                          ) : (
                            messages.map((e, i) => (
                              <Table.Row
                                key={i}
                                className={`bg-white dark:border-gray-700 dark:bg-gray-800 ${
                                  e.status === "completed"
                                    ? "read-msgs"
                                    : e.status === "cancelled"
                                    ? "reads-msgs"
                                    : ""
                                }`}
                              >
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                  {e.name}
                                </Table.Cell>
                                <Table.Cell className="text-ellipsis">
                                  {e?.message?.trim() === "" ||
                                  e?.message === undefined
                                    ? "No added message"
                                    : e?.message}
                                </Table.Cell>
                                <Table.Cell className="text-ellipsis">
                                  {e.phone}
                                </Table.Cell>
                                <Table.Cell>
                                  <p
                                    className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                                    onClick={() => displayOnModal(e)}
                                    style={{
                                      textDecoration: "none",
                                      cursor: "pointer",
                                    }}
                                  >
                                    View
                                  </p>
                                </Table.Cell>
                              </Table.Row>
                            ))
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  </Card>

                  {messages?.length > 0 && (
                    <div className="flex overflow-x-auto sm:justify-center mb-5">
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={onPageChange}
                        showIcons
                        previousLabel=""
                        nextLabel=""
                      />
                    </div>
                  )}
                </>
              ) : (
                <div className="flex justify-center items-center h-[100%]">
                  <Spinner
                    style={{ color: "#0078e7" }}
                    aria-label="info spinner example"
                    size="xl"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* modal for message */}
      <Modal show={openModal} size="md" onClose={onCloseModal} popup>
        <Modal.Header />
        <Modal.Body>
          <div className="space-y-6">
            <h3 className="text-xl font-medium text-gray-900 dark:text-white">
              <strong>Name: </strong>
              {message?.name}
            </h3>
            <div>
              <h4>
                <strong>Message: </strong>
                {message?.message?.trim() === "" ||
                message?.message === undefined
                  ? "No added message"
                  : message?.message}
              </h4>
            </div>
            <div>
              <h4>
                <strong>Phone: </strong> {message?.phone}
              </h4>
            </div>
            <div>
              <h4>
                <strong>Date Created: </strong>
                {formatDate(message?.createdAt)}
              </h4>
            </div>
            <div>
              <h4 className="capitalize">
                <strong>Status: </strong> {message?.status}
              </h4>
            </div>
            {message?.status === "pending" && (
              <div className="w-full flex justify-center gap-3">
                <Button
                  color="failure"
                  onClick={() => respondToMessage(message.id, "cancelled")}
                  disabled={loading2}
                >
                  Cancel
                </Button>
                <Button
                  color="success"
                  onClick={() => respondToMessage(message.id, "completed")}
                  disabled={loading2}
                >
                  Approve
                </Button>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>

      {/* modal for logout */}
      <Modal show={openModalOut} size="md" popup>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              {msg}
            </h3>
            <div className="flex justify-center gap-4">
              <Button color="failure" onClick={() => logOut()}>
                {"OK"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Message;

function formatDate(dateString) {
  const dateObj = new Date(dateString);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[dateObj.getMonth()];

  const desiredFormat = `${dateObj.getFullYear()}-${month}-${dateObj.getDate()}`;

  return desiredFormat;
}
