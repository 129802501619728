import React, { useContext, useState, useEffect } from "react";
import NavbarWithCTAButton from "../components/Nav";
import {
  Card,
  Spinner,
  Table,
  Button,
  Label,
  TextInput,
  Modal,
  Dropdown,
  Pagination,
} from "flowbite-react";
import { Store } from "../context/store";
import SideBar from "../components/Sidebar";
import { IoMdArrowBack, IoMdAddCircleOutline, IoMdSend } from "react-icons/io";
import { useNavigate, useSearchParams } from "react-router-dom";
import apiRequest from "../utils/apiRequest";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { FiFilter } from "react-icons/fi";
import { AiOutlineClear } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";

function Opex() {
  let store = useContext(Store);
  let [baseUrl] = store.url;
  let [user] = store.loggedInUser;
  let [opexArray, setOpexArray] = store.opexArray;
  let [loading, setLoading] = useState(false);
  let [editBody, setEditBody] = useState(false);
  let [editIndex, setEditIndex] = useState(null);
  const [secondCard, setSecondCard] = useState(false);
  const [invoiceBody, setInvoiceBody] = useState({});
  const navigate = useNavigate();
  let [msg, setMsg] = useState("");
  const [openModalOut, setOpenModalOut] = useState(false);
  let [opex, setOpex] = useState([]);
  let [loading1] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [totalPages, setTotalPages] = useState(1);
  let [status, setStatus] = useState("pending");
  const page = parseInt(searchParams.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(page);
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState("");
  let [singleOpex, setSingleOpex] = useState({});
  let [loading2, setLoading2] = useState(false);

  useEffect(() => {
    Promise.all([loadOpEx()])
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [page, status]);

  function onCloseModal() {
    setOpenModal(false);
  }

  const onPageChange = (page) => {
    setSearchParams({ page: page });
    setCurrentPage(page);
  };

  const loadOpEx = async () => {
    setLoading(true);
    try {
      let token = localStorage.getItem("royal-token");
      const url = `${baseUrl}/expenditures?type=OpEx&status=${status}&page=${page}`;
      const { data } = await apiRequest(url, "GET", null, token);
      setOpex(data.expenditures);
      setTotalPages(data.totalPages);
    } catch (error) {
      const unauthorizedErrors = [
        "Access Denied: Invalid or expired token. Please login again",
        "Access Denied: Please log in.",
        "Access Denied: Invalid Token.",
        "Access Denied: Invalid or expired token.",
        "Access Denied: Invalid or Expired Token.",
        "Access Denied: No token provided.",
      ];
      if (unauthorizedErrors.includes(error.message)) {
        setMsg("Session Expired!");
        setOpenModalOut(true);
        localStorage.removeItem("royal-token");
      } else {
        console.log(error);
      }
    }
  };

  const pushToInvoice = (e) => {
    if (!e.item || !e.price || !e.quantity) {
      setError("Please fill all fields !!!");
      return;
    }
    opexArray.rows.push(e);
    setError("");
    setInvoiceBody({
      item: "",
      price: "",
      quantity: "",
    });
  };

  const createOpEx = async () => {
    if (opexArray.rows.length === 0) {
      setError("At least one item must be added to the list.");
      return;
    }
    let body = { type: "OpEx", purpose: opexArray.rows };
    const confirmed = window.confirm(
      "Are you sure you want to create a OpEx Request?"
    );
    if (confirmed) {
      try {
        let token = localStorage.getItem("royal-token");
        const url = `${baseUrl}/expenditure`;
        let { message } = await apiRequest(url, "POST", body, token);
        setError(message);
        setOpexArray({ rows: [] });
        setInvoiceBody({});
        loadOpEx();
        setLoading(false);
      } catch (error) {
        setError(error.message);
        console.log(error);
      }
    }
  };

  const updateOpEx = async () => {
    let id = singleOpex.id;
    if (opexArray.rows.length === 0) {
      setError("At least one item must be added to the list.");
      return;
    }
    let body = { type: "OpEx", purpose: opexArray.rows };
    const confirmed = window.confirm(
      "Are you sure you want to update this OpEx Request?"
    );
    if (confirmed) {
      try {
        let token = localStorage.getItem("royal-token");
        const url = `${baseUrl}/expenditure/${id}`;
        let { message } = await apiRequest(url, "PUT", body, token);
        setError(message);
        setOpexArray({ rows: [] });
        setInvoiceBody({});
        loadOpEx();
        setLoading(false);
        setSingleOpex({});
        setSecondCard(false);
        setEditBody(false);
      } catch (error) {
        setError(error.message);
        console.log(error);
      } finally {
        setError("");
      }
    }
  };

  let displayOnModal = (e) => {
    setSingleOpex(e);
    setOpenModal(true);
  };

  let logOut = () => {
    navigate("/");
  };

  const changeOpExStatus = async (a, b) => {
    const confirmed = window.confirm(
      `Are you sure you want to ${b === "cancelled" ? "Cancel" : "Approve"}?`
    );
    if (confirmed) {
      setLoading2(true);
      try {
        let token = localStorage.getItem("royal-token");
        const url = `${baseUrl}/authorize/expenditure/${a}`;
        const { data, message } = await apiRequest(
          url,
          "PUT",
          { status: b },
          token
        );
        alert(message);
        setSingleOpex(data);
        setOpex((prevMessages) =>
          prevMessages.map((singleOpex) =>
            singleOpex.id === data.id ? data : singleOpex
          )
        );
        setLoading2(false);
      } catch (error) {
        alert(error.message);
        setLoading2(false);
        console.log(error);
      }
    }
  };

  const handleShowEdit = () => {
    setSecondCard(true);
    setEditBody(true);
    setOpexArray({ rows: singleOpex.purpose });
    setOpenModal(false);
  };

  const handleEdit = (a, b) => {
    setEditBody(true);
    setInvoiceBody({
      item: a.item,
      price: a.price,
      quantity: a.quantity,
    });
    setEditIndex(b);
  };

  const pushUpdateToInvoice = (e) => {
    if (!e.item || !e.price || !e.quantity) {
      setError("Please fill all fields !!!");
      return;
    }
    opexArray.rows[editIndex] = e;
    setError("");
    setInvoiceBody({
      item: "",
      price: "",
      quantity: "",
    });
    setEditBody(false);
  };

  return (
    <>
      <title>Opex</title>
      <meta
        name="description"
        content="Opex Page to the Royalmines Property Office"
      />
      <div className="container mx-auto">
        <NavbarWithCTAButton />

        {/* {!loading && ( */}
        <div className="container-fluid mx-auto">
          <div className="grid gap-4 grid-cols-6 md:grid-cols-5 mt-5">
            <SideBar opex="activess" expenditure="actives" />
            {!secondCard && (
              <div className="col-span-5 md:col-span-4" data-aos="flip-up">
                <div className="mb-4">
                  <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                    Operational Expenditures
                  </h5>
                  <div className="flex flex-wrap gap-2 mt-5 justify-end">
                    <Button color="success" onClick={() => setSecondCard(true)}>
                      <IoMdAddCircleOutline className="me-2" />
                      New OpEx
                    </Button>

                    <Dropdown
                      label={
                        <Button className="bg-#6eb5ff">
                          <FiFilter className="me-2" /> Filter By
                        </Button>
                      }
                      dismissOnClick={true}
                      inline
                    >
                      <Dropdown.Item
                        className={status === "" ? "active-dropdown" : ""}
                        onClick={() => {
                          setSearchParams({ page: 1 });
                          setStatus("");
                          setCurrentPage(1);
                        }}
                      >
                        All
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={
                          status === "pending" ? "active-dropdown" : ""
                        }
                        onClick={() => {
                          setSearchParams({ page: 1 });
                          setStatus("pending");
                          setCurrentPage(1);
                        }}
                      >
                        Pending
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={
                          status === "approved" ? "active-dropdown" : ""
                        }
                        onClick={() => {
                          setSearchParams({ page: 1 });
                          setStatus("approved");
                          setCurrentPage(1);
                        }}
                      >
                        Approved
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={
                          status === "cancelled" ? "active-dropdown" : ""
                        }
                        onClick={() => {
                          setSearchParams({ page: 1 });
                          setStatus("cancelled");
                          setCurrentPage(1);
                        }}
                      >
                        Cancelled
                      </Dropdown.Item>
                    </Dropdown>
                  </div>
                </div>
                {!loading1 ? (
                  <>
                    <Card className="rounded-xl my-4">
                      <div className="overflow-x-auto overflow-y-scroll h-[50vh] scrollbar-always-visible">
                        <Table hoverable>
                          <Table.Head>
                            <Table.HeadCell>Requested By</Table.HeadCell>
                            <Table.HeadCell>Date Created</Table.HeadCell>
                            <Table.HeadCell>Purpose</Table.HeadCell>
                            <Table.HeadCell>Status</Table.HeadCell>
                            <Table.HeadCell>Total</Table.HeadCell>
                            <Table.HeadCell>
                              <span className="sr-only">Edit</span>
                            </Table.HeadCell>
                          </Table.Head>

                          <Table.Body className="divide-y">
                            {opex.length === 0 ? (
                              <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                <Table.Cell
                                  colSpan={5}
                                  className="text-center py-10"
                                >
                                  No {status} opex.
                                </Table.Cell>
                              </Table.Row>
                            ) : (
                              opex.map((e, i) => (
                                <Table.Row
                                  key={i}
                                  className={`bg-white dark:border-gray-700 dark:bg-gray-800 ${
                                    e.status === "approved"
                                      ? "read-msgs"
                                      : e.status === "cancelled"
                                      ? "reads-msgs"
                                      : ""
                                  }`}
                                >
                                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                    {e.requestedBy.firstName}{" "}
                                    {e.requestedBy.lastName}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {formatDate(e?.createdAt)}
                                  </Table.Cell>
                                  <Table.Cell className="text-ellipsis">
                                    {e.purpose.map((a, b) => {
                                      return (
                                        <>
                                          <div className="mb-1">
                                            <p className="capitalize">
                                              Item: {a.item}
                                            </p>
                                            <p className="capitalize">
                                              Amount: {a.price.toLocaleString()}
                                            </p>
                                            <p className="capitalize">
                                              Qty: {a.quantity}
                                            </p>
                                          </div>
                                          <hr />
                                        </>
                                      );
                                    })}
                                  </Table.Cell>
                                  <Table.Cell className="text-ellipsis capitalize">
                                    {e.status}
                                  </Table.Cell>
                                  <Table.Cell className="text-ellipsis">
                                    &#8358; {e.total.toLocaleString()}
                                  </Table.Cell>
                                  <Table.Cell>
                                    <p
                                      className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                                      onClick={() => displayOnModal(e)}
                                      style={{
                                        textDecoration: "none",
                                        cursor: "pointer",
                                      }}
                                    >
                                      View
                                    </p>
                                  </Table.Cell>
                                </Table.Row>
                              ))
                            )}
                          </Table.Body>
                        </Table>
                      </div>
                    </Card>

                    {opex?.length > 0 && (
                      <div className="flex overflow-x-auto sm:justify-center mb-5">
                        <Pagination
                          currentPage={currentPage}
                          totalPages={totalPages}
                          onPageChange={onPageChange}
                          showIcons
                          previousLabel=""
                          nextLabel=""
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="flex justify-center items-center h-[100%]">
                    <Spinner
                      style={{ color: "#0078e7" }}
                      aria-label="info spinner example"
                      size="xl"
                    />
                  </div>
                )}
              </div>
            )}

            {/* add items to opex */}
            {secondCard && (
              <div className="col-span-5 md:col-span-4" data-aos="slide-right">
                <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                  Operational Expenditures
                </h5>
                <div className="flex flex-wrap gap-2 mt-5 justify-between">
                  <Button
                    onClick={() => {
                      setSecondCard(false);
                      setError("");
                      setOpexArray({ rows: [] });
                      setEditBody(false);
                    }}
                    className="bg-#6eb5ff"
                  >
                    <IoMdArrowBack className="me-2" />
                    Go Back
                  </Button>
                  {!editBody ? (
                    <Button className="bg-#6eb5ff" onClick={() => createOpEx()}>
                      Submit
                      <IoMdSend className="ms-2" />
                    </Button>
                  ) : (
                    <Button className="bg-#6eb5ff" onClick={() => updateOpEx()}>
                      Update
                      <IoMdSend className="ms-2" />
                    </Button>
                  )}
                </div>

                <p className="text-red-600 text-center my-3 font-bold">
                  {error}
                </p>
                <Card className="w-1/2 mx-auto">
                  <div className="flex flex-col gap-4">
                    {/* item */}
                    <div>
                      <div className="mb-2">
                        <div className="mb-2 block">
                          <Label htmlFor="item1" value="Item" />
                        </div>
                        <TextInput
                          id="item1"
                          type="text"
                          placeholder="Enter item"
                          value={invoiceBody.item}
                          onChange={(e) =>
                            setInvoiceBody({
                              ...invoiceBody,
                              item: e.target.value,
                            })
                          }
                        />
                      </div>

                      <div className="grid grid-cols-2 gap-4 mb-2">
                        <div className="col-span-1">
                          <div className="mb-2 block">
                            <Label htmlFor="Rate1" value="Amount" />
                          </div>
                          <TextInput
                            id="Rate1"
                            type="number"
                            placeholder="Enter Amount"
                            min={0}
                            value={invoiceBody.price}
                            onChange={(e) =>
                              setInvoiceBody({
                                ...invoiceBody,
                                price: parseInt(e.target.value),
                              })
                            }
                          />
                        </div>

                        <div className="col-span-1">
                          <div className="mb-2 block">
                            <Label htmlFor="Amount1" value="Quantity" />
                          </div>
                          <TextInput
                            id="Amount1"
                            type="number"
                            placeholder="Enter Quantity"
                            min={0}
                            value={invoiceBody.quantity}
                            onChange={(e) =>
                              setInvoiceBody({
                                ...invoiceBody,
                                quantity: parseInt(e.target.value),
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {!editBody ? (
                      <Button
                        type="button"
                        onClick={() => pushToInvoice(invoiceBody)}
                        className="bg-#6eb5ff"
                      >
                        Add to List
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        onClick={() => pushUpdateToInvoice(invoiceBody)}
                        className="bg-#6eb5ff"
                      >
                        Update List
                      </Button>
                    )}
                  </div>
                </Card>
              </div>
            )}
          </div>
        </div>
        {/* )} */}

        {/* {!loading && ( */}
        <>
          {secondCard && (
            <>
              <div className="flex flex-wrap gap-2 mt-5 justify-end">
                <Button
                  onClick={() => {
                    setOpexArray({ title: "", rows: [] });
                    setError("");
                  }}
                  className="bg-#6eb5ff"
                >
                  Clear
                  <AiOutlineClear className="ms-2" />
                </Button>
              </div>
              <Card className="rounded-xl my-4">
                <div className="">
                  <div className="overflow-x-auto">
                    <Table className="border-2 border-black text-xs">
                      <Table.Head>
                        <Table.HeadCell colSpan={4}>Item</Table.HeadCell>
                        <Table.HeadCell colSpan={1}>Amount</Table.HeadCell>
                        <Table.HeadCell colSpan={1}>Quantity</Table.HeadCell>
                        <Table.HeadCell colSpan={1}>Total</Table.HeadCell>
                        <Table.HeadCell colSpan={1}>Edit</Table.HeadCell>
                      </Table.Head>
                      {opexArray?.rows?.map((e, i) => {
                        return (
                          <Table.Body className="divide-y divide-x text-black">
                            <Table.Row>
                              <Table.Cell
                                className="whitespace-nowrap text-ellipsis"
                                colSpan={4}
                              >
                                {e.item}
                              </Table.Cell>
                              <Table.Cell colSpan={1}>{e.price}</Table.Cell>
                              <Table.Cell colSpan={1}>{e.quantity}</Table.Cell>
                              <Table.Cell colSpan={1}>
                                {(e.quantity * e.price).toLocaleString()}
                              </Table.Cell>
                              <Table.Cell
                                onClick={() => handleEdit(e, i)}
                                colSpan={1}
                              >
                                <FiEdit
                                  style={{ cursor: "pointer" }}
                                  color="#6db4ff"
                                />
                              </Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        );
                      })}
                    </Table>
                  </div>
                </div>
              </Card>
            </>
          )}
        </>
        {/* )} */}

        {/* modal for singleOpex */}
        <Modal
          dismissible
          show={openModal}
          size="md"
          onClose={onCloseModal}
          popup
        >
          <Modal.Header />
          <Modal.Body>
            <div className="space-y-6">
              <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                <strong>Requested By: </strong>
                {singleOpex?.requestedBy?.firstName}{" "}
                {singleOpex?.requestedBy?.lastName}
              </h3>
              <div>
                <h4>
                  <strong>Total: &#8358; </strong>
                  {singleOpex?.total?.toLocaleString()}
                </h4>
              </div>
              <div>
                <h4>
                  <strong>Date Created: </strong>
                  {formatDate(singleOpex?.createdAt)}
                </h4>
              </div>
              <div>
                <h4 className="capitalize">
                  <strong>Status: </strong> {singleOpex?.status}
                </h4>
              </div>
              {singleOpex?.authorizedBy && (
                <div>
                  <h4>
                    <strong>Authorized By: </strong>
                    {singleOpex?.authorizedBy?.firstName}{" "}
                    {singleOpex?.authorizedBy?.lastName}
                  </h4>
                </div>
              )}
              {singleOpex?.authorizedBy && (
                <div>
                  <h4>
                    <strong>Authorized Date: </strong>
                    {formatDate(singleOpex?.authorizedDate)}
                  </h4>
                </div>
              )}

              <div className="w-full flex justify-center gap-3">
                {singleOpex?.status === "pending" && (
                  <Button
                    color="failure"
                    onClick={() => changeOpExStatus(singleOpex.id, "cancelled")}
                    disabled={loading2}
                  >
                    Cancel
                  </Button>
                )}
                {singleOpex?.status === "pending" && (
                  <Button
                    color="success"
                    onClick={() => changeOpExStatus(singleOpex.id, "approved")}
                    disabled={loading2}
                  >
                    Approve
                  </Button>
                )}

                {singleOpex?.status === "pending" &&
                  user.id === singleOpex.requestedBy.id && (
                    <Button
                      color="warning"
                      onClick={handleShowEdit}
                      className="bg-blue-500"
                    >
                      Edit
                    </Button>
                  )}
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* modal for logout */}
        <Modal show={openModalOut} size="md" popup>
          <Modal.Header />
          <Modal.Body>
            <div className="text-center">
              <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
              <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                {msg}
              </h3>
              <div className="flex justify-center gap-4">
                <Button color="failure" onClick={() => logOut()}>
                  {"OK"}
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default Opex;

function formatDate(dateString) {
  const dateObj = new Date(dateString);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[dateObj.getMonth()];

  const desiredFormat = `${dateObj.getFullYear()}-${month}-${dateObj.getDate()}`;

  return desiredFormat;
}
