import React, { useContext, useState, useEffect } from "react";
import NavbarWithCTAButton from "../components/Nav";
import {
  Card,
  Spinner,
  Table,
  Button,
  Select,
  Modal,
  TextInput,
  Dropdown,
} from "flowbite-react";
import { Store } from "../context/store";
import { useNavigate } from "react-router-dom";
import SideBar from "../components/Sidebar";
import { IoMdAddCircleOutline } from "react-icons/io";
import apiRequest from "../utils/apiRequest";
import { HiOutlineExclamationCircle } from "react-icons/hi";

function Users() {
  let store = useContext(Store);
  let [baseUrl] = store.url;
  let [staff, setStaff] = useState([]);
  let [roles, setRoles] = useState([]);
  let [msg, setMsg] = useState("");
  let [error, setError] = useState("");
  let [errorColor, setErrorColor] = useState("");
  const [staffDetails, setStaffDetails] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    role: "",
  });
  let [singleStaff, setSingleStaff] = useState({});
  let [loading1, setLoading1] = useState(false);
  let [loading2, setLoading2] = useState(false);
  let [loading3, setLoading3] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalAddStaff, setOpenModalAddStaff] = useState(false);
  const [openModalOut, setOpenModalOut] = useState(false);
  const navigate = useNavigate();

  function onCloseModal() {
    setOpenModal(false);
  }

  function onCloseModalStaff() {
    setOpenModalAddStaff(false);
    setStaffDetails({
      email: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      role: "",
    });
    setError("");
  }

  useEffect(() => {
    Promise.all([loadStaff(), loadRoles()])
      .then(() => setLoading1(false))
      .catch(() => setLoading1(false));
  }, []);

  const loadStaff = async () => {
    setLoading1(true);
    try {
      let token = localStorage.getItem("royal-token");
      const url = `${baseUrl}/staff`;
      const { data } = await apiRequest(url, "GET", null, token);
      setStaff(data);
    } catch (error) {
      const unauthorizedErrors = [
        "Access Denied: Invalid or expired token. Please login again",
        "Access Denied: Please log in.",
        "Access Denied: Invalid Token.",
        "Access Denied: Invalid or expired token.",
        "Access Denied: Invalid or Expired Token.",
        "Access Denied: No token provided.",
      ];
      if (unauthorizedErrors.includes(error.message)) {
        setMsg("Session Expired!");
        setOpenModalOut(true);
        localStorage.removeItem("royal-token");
      } else {
        console.log(error);
      }
    }
  };

  const loadRoles = async () => {
    try {
      let token = localStorage.getItem("royal-token");
      const url = `${baseUrl}/roles`;
      const { data } = await apiRequest(url, "GET", null, token);
      setRoles(data);
    } catch (error) {
      console.log(error);
    }
  };

  const changeStaffStatus = async (a, b) => {
    const confirmed = window.confirm(
      "Are you sure you want to change staff's status?"
    );
    if (confirmed) {
      setLoading2(true);
      try {
        let token = localStorage.getItem("royal-token");
        const url = `${baseUrl}/staff/${a}`;
        const { data } = await apiRequest(url, "PUT", { status: b }, token);
        setSingleStaff(data);
        setStaff((prevMessages) =>
          prevMessages.map((singleStaff) =>
            singleStaff.id === data.id ? data : singleStaff
          )
        );
        setLoading2(false);
      } catch (error) {
        setLoading2(false);
        console.log(error);
      }
    }
  };

  const register = async (a, b) => {
    if (
      !staffDetails.confirmPassword ||
      !staffDetails.password ||
      !staffDetails.email ||
      !staffDetails.firstName ||
      !staffDetails.lastName ||
      !staffDetails.role
    ) {
      setErrorColor("red");
      setError("Please fill all missing fields.");
      return;
    }
    if (!validateEmail(staffDetails.email)) {
      setErrorColor("red");
      setError(
        "Please enter an email address ending with @royalminesproperty.com"
      );
      return;
    }
    if (!validatePassword(staffDetails.password)) {
      setErrorColor("red");
      setError(
        "Password: Minimun of 8 characters, including uppercase, lowercase, numbers, and special characters."
      );
      return;
    }
    if (staffDetails.confirmPassword !== staffDetails.password) {
      setErrorColor("red");
      setError("Password and Confirm Password do not match.");
      return;
    }
    const confirmed = window.confirm(
      "Are you sure you want to register a new staff?"
    );
    if (confirmed) {
      setLoading3(true);
      try {
        let token = localStorage.getItem("royal-token");
        const url = `${baseUrl}/register`;
        let { data } = await apiRequest(url, "POST", staffDetails, token);
        setErrorColor("green");
        setError("Successfully registered.");
        setLoading3(false);
        onCloseModalStaff();
        loadStaff();
        setLoading1(false);
      } catch (error) {
        setErrorColor("red");
        setError(error.message);
        setLoading3(false);
        console.log(error);
      }
    }
  };

  const changeStaffRole = async (a, b) => {
    const confirmed = window.confirm(
      "Are you sure you want to change staff's role?"
    );
    if (confirmed) {
      setLoading2(true);
      try {
        let token = localStorage.getItem("royal-token");
        const url = `${baseUrl}/staff/${a}`;
        const { data } = await apiRequest(url, "PUT", { role: b }, token);
        setSingleStaff(data);
        setStaff((prevMessages) =>
          prevMessages.map((singleStaff) =>
            singleStaff.id === data.id ? data : singleStaff
          )
        );
        setLoading2(false);
      } catch (error) {
        setLoading2(false);
        console.log(error.message);
      }
    }
  };

  let displayOnModal = (e) => {
    setSingleStaff(e);
    setOpenModal(true);
  };

  let logOut = () => {
    navigate("/");
  };

  return (
    <>
      <title>Staff</title>
      <meta
        name="description"
        content="Staff Page to the Royalmines Property Office"
      />
      <div className="container mx-auto">
        <NavbarWithCTAButton />

        <div className="container  fixed ">
          <div className="grid gap-4 grid-cols-6 md:grid-cols-5 mt-5">
            <SideBar users="actives" staff="activess" />
            <div className="col-span-5 md:col-span-4" data-aos="flip-up">
              <div className="mb-4">
                <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                  Staff
                </h5>
                <div className="flex flex-wrap gap-2 mt-5 justify-end">
                  <Button
                    color="success"
                    onClick={() => setOpenModalAddStaff(true)}
                  >
                    <IoMdAddCircleOutline className="me-2" />
                    Add Staff
                  </Button>
                </div>
              </div>
              {!loading1 ? (
                <>
                  <Card className="rounded-xl my-4">
                    <div className="overflow-x-auto overflow-y-scroll h-[45vh] scrollbar-always-visible">
                      <Table hoverable>
                        <Table.Head>
                          <Table.HeadCell>Name</Table.HeadCell>
                          <Table.HeadCell>Email</Table.HeadCell>
                          <Table.HeadCell>Phone</Table.HeadCell>
                          <Table.HeadCell>Role</Table.HeadCell>
                          <Table.HeadCell>
                            <span className="sr-only">Edit</span>
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          {staff.length === 0 ? (
                            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                              <Table.Cell
                                colSpan={3}
                                className="text-center py-4"
                              >
                                No staff added yet.
                              </Table.Cell>
                            </Table.Row>
                          ) : (
                            staff.map((e, i) => (
                              <Table.Row
                                key={i}
                                className={`bg-white dark:border-gray-700 dark:bg-gray-800 ${
                                  e.status === "active"
                                    ? "read-msgs"
                                    : e.status === "inactive"
                                    ? "reads-msgs"
                                    : ""
                                }`}
                              >
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                  {e.firstName} {e.lastName}
                                </Table.Cell>
                                <Table.Cell className="text-ellipsis">
                                  {e?.email}
                                </Table.Cell>
                                <Table.Cell className="text-ellipsis">
                                  {e.phone}
                                </Table.Cell>
                                <Table.Cell className="text-ellipsis capitalize">
                                  {e.role?.title}
                                </Table.Cell>
                                <Table.Cell>
                                  <p
                                    className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                                    onClick={() => displayOnModal(e)}
                                    style={{
                                      textDecoration: "none",
                                      cursor: "pointer",
                                    }}
                                  >
                                    View
                                  </p>
                                </Table.Cell>
                              </Table.Row>
                            ))
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  </Card>
                </>
              ) : (
                <div className="flex justify-center items-center h-[100%]">
                  <Spinner
                    style={{ color: "#0078e7" }}
                    aria-label="info spinner example"
                    size="xl"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* modal for singleStaff */}
      <Modal
        dismissible
        show={openModal}
        size="md"
        onClose={onCloseModal}
        popup
      >
        <Modal.Header />
        <Modal.Body>
          <div className="space-y-6">
            <h3 className="text-xl font-medium text-gray-900 dark:text-white">
              <strong>Name: </strong>
              {singleStaff?.firstName} {singleStaff?.lastName}
            </h3>
            <div>
              <h4>
                <strong>Email: </strong>
                {singleStaff?.email?.trim() === "" ||
                singleStaff?.email === undefined
                  ? "No added email"
                  : singleStaff?.email}
              </h4>
            </div>
            <div>
              <h4>
                <strong>Phone: </strong> {singleStaff?.phone}
              </h4>
            </div>
            <div>
              <h4>
                <strong>Date Created: </strong>
                {formatDate(singleStaff?.createdAt)}
              </h4>
            </div>
            <div>
              <h4 className="capitalize">
                <strong>Role: </strong> {singleStaff?.role?.title}
              </h4>
            </div>
            <div>
              <h4 className="capitalize">
                <strong>Status: </strong> {singleStaff?.status}
              </h4>
            </div>

            <div className="w-full flex justify-center gap-3">
              <Dropdown label="Change Role" dismissOnClick={true}>
                {roles?.map((role) => {
                  return (
                    <Dropdown.Item
                      className="capitalize"
                      onClick={() => changeStaffRole(singleStaff.id, role.id)}
                    >
                      {role.title}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown>
              {singleStaff?.status === "active" && (
                <Button
                  color="failure"
                  onClick={() => changeStaffStatus(singleStaff.id, "inactive")}
                  disabled={loading2}
                >
                  Deactivate
                </Button>
              )}
              {singleStaff?.status === "inactive" && (
                <Button
                  color="success"
                  onClick={() => changeStaffStatus(singleStaff.id, "active")}
                  disabled={loading2}
                >
                  Activate
                </Button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* modal for logout */}
      <Modal show={openModalOut} size="md" popup>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              {msg}
            </h3>
            <div className="flex justify-center gap-4">
              <Button color="failure" onClick={() => logOut()}>
                {"OK"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* modal for add staff */}
      <Modal
        show={openModalAddStaff}
        size="md"
        onClose={onCloseModalStaff}
        popup
      >
        <Modal.Header />
        <Modal.Body>
          <div className="space-y-6">
            <h3 className="text-xl font-medium text-gray-900 dark:text-white text-center">
              Register Staff
            </h3>
            <div className="flex justify-center">
              <small
                style={{
                  color: errorColor,
                  textAlign: "center",
                }}
              >
                {error}
              </small>
            </div>
            <div>
              <TextInput
                id="firstName"
                placeholder="First Name"
                value={staffDetails.firstName}
                onChange={(event) =>
                  setStaffDetails({
                    ...staffDetails,
                    firstName: event.target.value,
                  })
                }
                required
              />
            </div>
            <div>
              <TextInput
                id="lastName"
                placeholder="Last Name"
                value={staffDetails.lastName}
                onChange={(event) =>
                  setStaffDetails({
                    ...staffDetails,
                    lastName: event.target.value,
                  })
                }
                required
              />
            </div>
            <div>
              <TextInput
                id="email"
                placeholder="Email Address"
                value={staffDetails.email}
                onChange={(event) =>
                  setStaffDetails({
                    ...staffDetails,
                    email: event.target.value,
                  })
                }
                required
              />
            </div>
            <div>
              <Select
                id="Role"
                onChange={(event) =>
                  setStaffDetails({
                    ...staffDetails,
                    role: event.target.value,
                  })
                }
                required
                style={{ textTransform: "capitalize" }}
              >
                <option value="">Select a Role</option>;
                {roles.map((role) => {
                  return <option value={role.id}>{role.title}</option>;
                })}
              </Select>
            </div>
            <div>
              <TextInput
                id="password"
                type="password"
                placeholder="Password"
                value={staffDetails.password}
                onChange={(event) =>
                  setStaffDetails({
                    ...staffDetails,
                    password: event.target.value,
                  })
                }
                required
              />
            </div>
            <div>
              <TextInput
                id="password1"
                type="password"
                placeholder="Confirm Password"
                value={staffDetails.confirmPassword}
                onChange={(event) =>
                  setStaffDetails({
                    ...staffDetails,
                    confirmPassword: event.target.value,
                  })
                }
                required
              />
            </div>
            <div className="w-full flex justify-center">
              <Button disabled={loading3} onClick={() => register()}>
                {loading3 ? "Loading..." : "Register"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Users;

function formatDate(dateString) {
  const dateObj = new Date(dateString);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[dateObj.getMonth()];

  const desiredFormat = `${dateObj.getFullYear()}-${month}-${dateObj.getDate()}`;

  return desiredFormat;
}

function validatePassword(password) {
  const re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{8,}$/;
  return re.test(password);
}

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@royalminesproperty.com/;
  return re.test(String(email).toLowerCase());
}
