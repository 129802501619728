import React, { useContext, useState, useEffect } from "react";
import NavbarWithCTAButton from "../components/Nav";
import {
  Card,
  Spinner,
  Table,
  Button,
  Pagination,
  Modal,
  Dropdown,
  TextInput,
  Select,
} from "flowbite-react";
import { Store } from "../context/store";
import SideBar from "../components/Sidebar";
import { IoMdAddCircleOutline } from "react-icons/io";
import { FiFilter } from "react-icons/fi";
import apiRequest from "../utils/apiRequest";
import { useNavigate, useSearchParams } from "react-router-dom";
import { HiOutlineExclamationCircle } from "react-icons/hi";

function Tasks() {
  let store = useContext(Store);
  let [baseUrl] = store.url;
  let [tasks, setTasks] = useState([]);
  let [singleTask, setSingleTask] = useState({});
  let [loading, setLoading] = useState(false);
  let [loading1, setLoading1] = useState(false);
  let [loading2, setLoading2] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModal1, setOpenModal1] = useState(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [totalPages, setTotalPages] = useState(1);
  const page = parseInt(searchParams.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(page);
  let [status, setStatus] = useState("pending");
  let [msg, setMsg] = useState("");
  const [openModalOut, setOpenModalOut] = useState(false);
  const [error, setError] = useState("");
  let [staff, setStaff] = useState([]);
  const [task, setTask] = useState({
    userId: "",
    task: "",
    comment: "",
  });
  let [errorColor, setErrorColor] = useState("");
  let [loading3, setLoading3] = useState(false);
  const onPageChange = (page) => {
    setSearchParams({ page: page });
    setCurrentPage(page);
  };

  function onCloseModal() {
    setOpenModal(false);
    setSingleTask({});
  }

  function onCloseModal1() {
    setOpenModal1(false);
    setTask({ userId: "", task: "", comment: "" });
  }

  useEffect(() => {
    Promise.all([loadTasks(), loadStaff()])
      .then(() => setLoading1(false))
      .catch(() => setLoading1(false));
  }, [page, status]);

  const loadTasks = async () => {
    setLoading1(true);
    try {
      let token = localStorage.getItem("royal-token");
      const url = `${baseUrl}/tasks?page=${page}&status=${status}`;
      const { data } = await apiRequest(url, "GET", null, token);
      setTasks(data.tasks);
      setTotalPages(data.totalPages);
    } catch (error) {
      const unauthorizedErrors = [
        "Access Denied: Invalid or expired token. Please login again",
        "Access Denied: Please log in.",
        "Access Denied: Invalid Token.",
        "Access Denied: Invalid or expired token.",
        "Access Denied: Invalid or Expired Token.",
        "Access Denied: No token provided.",
      ];
      if (unauthorizedErrors.includes(error.message)) {
        setMsg("Session Expired!");
        setOpenModalOut(true);
        localStorage.removeItem("royal-token");
      } else {
        console.log(error);
      }
    }
  };
  const loadStaff = async () => {
    setLoading1(true);
    try {
      let token = localStorage.getItem("royal-token");
      const url = `${baseUrl}/staff`;
      const { data } = await apiRequest(url, "GET", null, token);
      setStaff(data);
    } catch (error) {
      const unauthorizedErrors = [
        "Access Denied: Invalid or expired token. Please login again",
        "Access Denied: Please log in.",
        "Access Denied: Invalid Token.",
        "Access Denied: Invalid or expired token.",
        "Access Denied: Invalid or Expired Token.",
        "Access Denied: No token provided.",
      ];
      if (unauthorizedErrors.includes(error.message)) {
        setMsg("Session Expired!");
        setOpenModalOut(true);
        localStorage.removeItem("royal-token");
      } else {
        console.log(error);
      }
    }
  };

  const respondToTask = async (a, b) => {
    setLoading2(true);
    try {
      let token = localStorage.getItem("royal-token");
      const url = `${baseUrl}/task/${a}`;
      const { data } = await apiRequest(url, "PUT", { status: b }, token);
      setSingleTask(data);
      setTasks((prevMessages) =>
        prevMessages.map((singleTask) =>
          singleTask.id === data.id ? data : singleTask
        )
      );
      setLoading2(false);
    } catch (error) {
      setLoading2(false);
      console.log(error);
    }
  };

  let displayOnModal = (e) => {
    setSingleTask(e);
    setOpenModal(true);
  };

  const createNewTask = async () => {
    if (!task.userId) {
      setErrorColor("red");
      setError("Please select a staff to assign task to.");
      return;
    }
    if (!task.task) {
      setErrorColor("red");
      setError("Please add the task.");
      return;
    }
    const confirmed = window.confirm("Are you sure you want to assign a task?");
    if (confirmed) {
      try {
        let token = localStorage.getItem("royal-token");
        const url = `${baseUrl}/task`;
        let { message } = await apiRequest(url, "POST", task, token);
        setError(message);
        setTask({
          userId: "",
          task: "",
          comment: "",
        });
        loadTasks();
        setLoading1(false);
      } catch (error) {
        setError(error.message);
        console.log(error);
      }
    }
  };

  let logOut = () => {
    navigate("/");
  };

  return (
    <>
      <title>Tasks</title>
      <meta
        name="description"
        content="Tasks Page to the Royalmines Property Office"
      />
      <div className="container mx-auto">
        <NavbarWithCTAButton />

        <div className="container  fixed ">
          <div className="grid gap-4 grid-cols-6 md:grid-cols-5 mt-5">
            <SideBar tasks="actives" />
            <div className="col-span-5 md:col-span-4" data-aos="flip-up">
              <div className="mb-4">
                <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                  Tasks
                </h5>
                <div className="flex flex-wrap gap-2 mt-5 justify-end">
                  <Button color="success" onClick={() => setOpenModal1(true)}>
                    <IoMdAddCircleOutline className="me-2" />
                    New Task
                  </Button>
                  <Dropdown
                    label={
                      <Button className="bg-#6eb5ff">
                        <FiFilter className="me-2" /> Filter By
                      </Button>
                    }
                    dismissOnClick={true}
                    inline
                  >
                    <Dropdown.Item
                      className={status === "" ? "active-dropdown" : ""}
                      onClick={() => {
                        setSearchParams({ page: 1 });
                        setStatus("");
                        setCurrentPage(1);
                      }}
                    >
                      All
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={status === "pending" ? "active-dropdown" : ""}
                      onClick={() => {
                        setSearchParams({ page: 1 });
                        setStatus("pending");
                        setCurrentPage(1);
                      }}
                    >
                      Pending
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={
                        status === "completed" ? "active-dropdown" : ""
                      }
                      onClick={() => {
                        setSearchParams({ page: 1 });
                        setStatus("completed");
                        setCurrentPage(1);
                      }}
                    >
                      Completed
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={
                        status === "cancelled" ? "active-dropdown" : ""
                      }
                      onClick={() => {
                        setSearchParams({ page: 1 });
                        setStatus("cancelled");
                        setCurrentPage(1);
                      }}
                    >
                      Cancelled
                    </Dropdown.Item>
                  </Dropdown>
                </div>
              </div>
              {!loading1 ? (
                <>
                  <Card className="rounded-xl my-4">
                    <div className="overflow-x-auto overflow-y-scroll h-[45vh] scrollbar-always-visible">
                      <Table hoverable>
                        <Table.Head>
                          <Table.HeadCell>Assigned By</Table.HeadCell>
                          <Table.HeadCell>Assigned To</Table.HeadCell>
                          <Table.HeadCell>Description</Table.HeadCell>
                          <Table.HeadCell>Status</Table.HeadCell>
                          <Table.HeadCell>
                            <span className="sr-only">Edit</span>
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          {tasks.length === 0 ? (
                            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                              <Table.Cell
                                colSpan={5}
                                className="text-center py-4"
                              >
                                No tasks found.
                              </Table.Cell>
                            </Table.Row>
                          ) : (
                            tasks.map((e, i) => (
                              <Table.Row
                                key={i}
                                className={`bg-white dark:border-gray-700 dark:bg-gray-800 ${
                                  e.status === "completed"
                                    ? "read-msgs"
                                    : e.status === "cancelled"
                                    ? "reads-msgs"
                                    : ""
                                }`}
                              >
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                  {e.assignedById.firstName}{" "}
                                  {e.assignedById.lastName}
                                </Table.Cell>
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                  {e.userId.firstName} {e.userId.lastName}
                                </Table.Cell>
                                <Table.Cell className="text-ellipsis">
                                  {e?.task}
                                </Table.Cell>
                                <Table.Cell className="capitalize">
                                  {e.status}
                                </Table.Cell>
                                <Table.Cell>
                                  <p
                                    className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                                    onClick={() => displayOnModal(e)}
                                    style={{
                                      textDecoration: "none",
                                      cursor: "pointer",
                                    }}
                                  >
                                    View
                                  </p>
                                </Table.Cell>
                              </Table.Row>
                            ))
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  </Card>

                  {tasks?.length > 0 && (
                    <div className="flex overflow-x-auto sm:justify-center mb-5">
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={onPageChange}
                        showIcons
                        previousLabel=""
                        nextLabel=""
                      />
                    </div>
                  )}
                </>
              ) : (
                <div className="flex justify-center items-center h-[100%]">
                  <Spinner
                    style={{ color: "#0078e7" }}
                    aria-label="info spinner example"
                    size="xl"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* modal for task */}
      <Modal show={openModal} size="md" onClose={onCloseModal} popup>
        <Modal.Header />
        <Modal.Body>
          <div className="space-y-6">
            <h3 className="text-xl font-medium text-gray-900 dark:text-white">
              <strong>Assigned By: </strong>
              {singleTask?.assignedById?.firstName}{" "}
              {singleTask?.assignedById?.lastName}
            </h3>
            <h3 className="text-xl font-medium text-gray-900 dark:text-white">
              <strong>Assigned To: </strong>
              {singleTask?.userId?.firstName} {singleTask?.userId?.lastName}
            </h3>
            <div>
              <h4>
                <strong>Description: </strong>
                {singleTask?.task}
              </h4>
            </div>
            <div>
              <h4>
                <strong>Date Created: </strong>
                {formatDate(singleTask?.createdAt)}
              </h4>
            </div>
            <div>
              <h4 className="capitalize">
                <strong>Status: </strong> {singleTask?.status}
              </h4>
            </div>
            {singleTask?.comments?.length > 0 && (
              <div>
                <h4>
                  <strong>Comments: </strong>{" "}
                  {singleTask?.comments?.map((a, b) => {
                    return (
                      <li className="mb-3 bg-slate-200 p-2 rounded">
                        Comment: {a.comment}
                        <br />
                        By: {a.by.firstName} {a.by.lastName}
                        <br />
                        Timestamp: {formatDate(a.time)}
                      </li>
                    );
                  })}
                </h4>
              </div>
            )}
            {singleTask?.status === "pending" && (
              <div className="w-full flex justify-center gap-3">
                <Button
                  color="failure"
                  onClick={() => respondToTask(singleTask.id, "cancelled")}
                  disabled={loading2}
                >
                  Cancel
                </Button>
                <Button
                  color="success"
                  onClick={() => respondToTask(singleTask.id, "completed")}
                  disabled={loading2}
                >
                  Complete
                </Button>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>

      {/* modal for add task */}
      <Modal show={openModal1} size="md" onClose={onCloseModal1} popup>
        <Modal.Header />
        <Modal.Body>
          <div className="space-y-6">
            <h3 className="text-xl font-medium text-gray-900 dark:text-white text-center">
              Add New Task
            </h3>
            <div className="flex justify-center">
              <small
                style={{
                  color: errorColor,
                  textAlign: "center",
                }}
              >
                {error}
              </small>
            </div>
            <div>
              <TextInput
                id="task"
                placeholder="Task Description"
                value={task.task}
                onChange={(event) =>
                  setTask({
                    ...task,
                    task: event.target.value,
                  })
                }
                required
              />
            </div>

            <div>
              <TextInput
                id="comment"
                placeholder="Add Comment if any."
                value={task.comment}
                onChange={(event) =>
                  setTask({
                    ...task,
                    comment: event.target.value,
                  })
                }
                required
              />
            </div>

            <div>
              <Select
                id="staffId"
                onChange={(event) =>
                  setTask({
                    ...task,
                    userId: event.target.value,
                  })
                }
                required
                style={{ textTransform: "capitalize" }}
              >
                <option value="">Select a Staff</option>
                {staff?.map((a, b) => {
                  return (
                    <option key={b} value={a.id}>
                      {a.firstName} {a.lastName}
                    </option>
                  );
                })}
              </Select>
            </div>
            <div className="w-full flex justify-center">
              <Button disabled={loading3} onClick={() => createNewTask()}>
                {loading3 ? "Loading..." : "Assign Task"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* modal for logout */}
      <Modal show={openModalOut} size="md" popup>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              {msg}
            </h3>
            <div className="flex justify-center gap-4">
              <Button color="failure" onClick={() => logOut()}>
                {"OK"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Tasks;

function formatDate(dateString) {
  const dateObj = new Date(dateString);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const month = monthNames[dateObj.getMonth()];
  const year = dateObj.getFullYear();
  const day = dateObj.getDate();

  let hours = dateObj.getHours();
  const minutes = dateObj.getMinutes().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // The hour '0' should be '12'

  const formattedDate = `${year}-${month}-${day}`;
  const formattedTime = `${hours}:${minutes} ${ampm}`;

  return `${formattedDate} ${formattedTime}`;
}
