import React, { useContext, useState, useEffect } from "react";
import NavbarWithCTAButton from "../components/Nav";
import {
  Card,
  Spinner,
  Table,
  Banner,
  Button,
  Pagination,
  Modal,
  Dropdown,
} from "flowbite-react";
import { HiX, HiOutlineExclamationCircle } from "react-icons/hi";
import { Store } from "../context/store";
import { useNavigate, useSearchParams } from "react-router-dom";
import SideBar from "../components/Sidebar";
import apiRequest from "../utils/apiRequest";
import Notify from "../components/Notify";
import { MdAnnouncement } from "react-icons/md";
import { FiFilter } from "react-icons/fi";

function Home() {
  let store = useContext(Store);
  let [baseUrl] = store.url;
  let [user, setUser] = useState({});
  let [appointments, setAppointments] = useState([]);
  let [appointment, setAppointment] = useState({});
  let [loading, setLoading] = useState(false);
  let [loading1, setLoading1] = useState(false);
  let [loading2, setLoading2] = useState(false);
  let [unread] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [totalPages, setTotalPages] = useState(1);
  const page = parseInt(searchParams.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(page);
  let [status, setStatus] = useState("pending");
  let [msg, setMsg] = useState("");
  const [openModalOut, setOpenModalOut] = useState(false);
  const onPageChange = (page) => {
    setSearchParams({ page: page });
    setCurrentPage(page);
  };

  function onCloseModal() {
    setOpenModal(false);
    setAppointment({});
  }

  useEffect(() => {
    Promise.all([loadUser()])
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    Promise.all([loadAppointments()])
      .then(() => setLoading1(false))
      .catch(() => setLoading1(false));
  }, [page, status]);

  const loadUser = async () => {
    setLoading(true);
    try {
      let token = localStorage.getItem("royal-token");
      const url = `${baseUrl}/token`;
      const { data } = await apiRequest(url, "GET", null, token);
      setUser(data);
    } catch (error) {
      const unauthorizedErrors = [
        "Access Denied: Invalid or expired token. Please login again",
        "Access Denied: Please log in.",
        "Access Denied: Invalid Token.",
        "Access Denied: Invalid or expired token.",
        "Access Denied: Invalid or Expired Token.",
        "Access Denied: No token provided.",
      ];
      if (unauthorizedErrors.includes(error.message)) {
        setMsg("Session Expired!");
        setOpenModalOut(true);
        localStorage.removeItem("royal-token");
      } else {
        console.log(error);
      }
    }
  };

  const loadAppointments = async () => {
    setLoading1(true);
    try {
      let token = localStorage.getItem("royal-token");
      const url = `${baseUrl}/appointments?type=appointment&page=${page}&status=${status}`;
      const { data } = await apiRequest(url, "GET", null, token);
      setAppointments(data.appointments);
      setTotalPages(data.totalPages);
    } catch (error) {
      const unauthorizedErrors = [
        "Access Denied: Invalid or expired token. Please login again",
        "Access Denied: Please log in.",
        "Access Denied: Invalid Token.",
        "Access Denied: Invalid or expired token.",
        "Access Denied: Invalid or Expired Token.",
        "Access Denied: No token provided.",
      ];
      if (unauthorizedErrors.includes(error.message)) {
        setMsg("Session Expired!");
        setOpenModalOut(true);
        localStorage.removeItem("royal-token");
      } else {
        console.log(error);
      }
    }
  };

  const respondToAppointment = async (a, b) => {
    setLoading2(true);
    try {
      let token = localStorage.getItem("royal-token");
      const url = `${baseUrl}/appointment/${a}`;
      const { data } = await apiRequest(url, "PUT", { status: b }, token);
      setAppointment(data);
      setAppointments((prevAppointments) =>
        prevAppointments.map((appointment) =>
          appointment.id === data.id ? data : appointment
        )
      );
      setLoading2(false);
    } catch (error) {
      setLoading2(false);
      const unauthorizedErrors = [
        "Access Denied: Invalid or expired token. Please login again",
        "Access Denied: Please log in.",
        "Access Denied: Invalid Token.",
        "Access Denied: Invalid or expired token.",
        "Access Denied: Invalid or Expired Token.",
        "Access Denied: No token provided.",
      ];
      if (unauthorizedErrors.includes(error.message)) {
        setMsg("Session Expired!");
        setOpenModalOut(true);
        localStorage.removeItem("royal-token");
      } else {
        console.log(error);
      }
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-[100vh]">
        <Spinner
          style={{ color: "#0078e7" }}
          aria-label="info spinner example"
          size="xl"
        />
      </div>
    );
  }

  let displayOnModal = (e) => {
    setAppointment(e);
    setOpenModal(true);
  };

  let logOut = () => {
    navigate("/");
  };

  return (
    <>
      <title>Notifications</title>
      <meta
        name="description"
        content="Notifications Page to the Royalmines Property Office"
      />
      <div className="container mx-auto">
        <NavbarWithCTAButton />

        {!loading && (
          <div className="container  fixed ">
            {/* <Banner>
              <div className="flex w-full justify-between border-b border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700 mt-3">
                <div className="mx-auto flex items-center">
                  <Notify />
                </div>
              </div>
            </Banner> */}
            {/* {user && (
              <Banner>
                <div className="flex w-full justify-between border-b border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700 mt-3">
                  <div className="mx-auto flex items-center">
                    <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                      <MdAnnouncement className="mr-4 h-4 w-4" />
                      <span className="[&_p]:inline">
                        Welcome{" "}
                        <p className="font-bold">
                          {user.firstName} {user.lastName}
                        </p>
                        . You have{" "}
                        <p className="inline font-medium underline decoration-solid underline-offset-2 hover:no-underline dark:text-cyan-500">
                          {unread} unread messages
                        </p>{" "}
                        out of 20
                      </span>
                    </p>
                  </div>
                  <Banner.CollapseButton
                    color="gray"
                    className="border-0 bg-transparent text-gray-500 dark:text-gray-400"
                  >
                    <HiX className="h-4 w-4" />
                  </Banner.CollapseButton>
                </div>
              </Banner>
            )} */}

            <div className="grid gap-4 grid-cols-6 md:grid-cols-5 mt-5">
              <SideBar dashboard="actives" appointments="activess" />
              <div className="col-span-5 md:col-span-4" data-aos="flip-up">
                <div className="mb-4">
                  <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                    Appointments
                  </h5>
                  <div className="flex flex-wrap gap-2 mt-5 justify-end">
                    <Dropdown
                      label={
                        <Button className="bg-#6eb5ff">
                          <FiFilter className="me-2" /> Filter By
                        </Button>
                      }
                      dismissOnClick={true}
                      inline
                    >
                      <Dropdown.Item
                        className={status === "" ? "active-dropdown" : ""}
                        onClick={() => {
                          setSearchParams({ page: 1 });
                          setStatus("");
                          setCurrentPage(1);
                        }}
                      >
                        All
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={
                          status === "pending" ? "active-dropdown" : ""
                        }
                        onClick={() => {
                          setSearchParams({ page: 1 });
                          setStatus("pending");
                          setCurrentPage(1);
                        }}
                      >
                        Pending
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={
                          status === "completed" ? "active-dropdown" : ""
                        }
                        onClick={() => {
                          setSearchParams({ page: 1 });
                          setStatus("completed");
                          setCurrentPage(1);
                        }}
                      >
                        Completed
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={
                          status === "cancelled" ? "active-dropdown" : ""
                        }
                        onClick={() => {
                          setSearchParams({ page: 1 });
                          setStatus("cancelled");
                          setCurrentPage(1);
                        }}
                      >
                        Cancelled
                      </Dropdown.Item>
                    </Dropdown>
                  </div>
                </div>
                {!loading1 ? (
                  <>
                    <Card className="rounded-xl my-4">
                      <div className="overflow-x-auto overflow-y-scroll h-[50vh] scrollbar-always-visible">
                        <Table hoverable>
                          <Table.Head>
                            <Table.HeadCell>Name</Table.HeadCell>
                            <Table.HeadCell>Message</Table.HeadCell>
                            <Table.HeadCell>Phone</Table.HeadCell>
                            <Table.HeadCell>
                              <span className="sr-only">Edit</span>
                            </Table.HeadCell>
                          </Table.Head>

                          <Table.Body className="divide-y">
                            {appointments.length === 0 ? (
                              <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                <Table.Cell
                                  colSpan={3}
                                  className="text-center py-4"
                                >
                                  No pending appointments received yet.
                                </Table.Cell>
                              </Table.Row>
                            ) : (
                              appointments.map((e, i) => (
                                <Table.Row
                                  key={i}
                                  className={`bg-white dark:border-gray-700 dark:bg-gray-800 ${
                                    e.status === "completed"
                                      ? "read-msgs"
                                      : e.status === "cancelled"
                                      ? "reads-msgs"
                                      : ""
                                  }`}
                                >
                                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                    {e.name}
                                  </Table.Cell>
                                  <Table.Cell className="text-ellipsis">
                                    {e?.message?.trim() === "" ||
                                    e?.message === undefined
                                      ? "No added message"
                                      : e?.message}
                                  </Table.Cell>
                                  <Table.Cell className="text-ellipsis">
                                    {e.phone}
                                  </Table.Cell>
                                  <Table.Cell>
                                    <p
                                      className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                                      onClick={() => displayOnModal(e)}
                                      style={{
                                        textDecoration: "none",
                                        cursor: "pointer",
                                      }}
                                    >
                                      View
                                    </p>
                                  </Table.Cell>
                                </Table.Row>
                              ))
                            )}
                          </Table.Body>
                        </Table>
                      </div>
                    </Card>

                    {appointments?.length > 0 && (
                      <div className="flex overflow-x-auto sm:justify-center mb-5">
                        <Pagination
                          currentPage={currentPage}
                          totalPages={totalPages}
                          onPageChange={onPageChange}
                          showIcons
                          previousLabel=""
                          nextLabel=""
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="flex justify-center items-center h-[100%]">
                    <Spinner
                      style={{ color: "#0078e7" }}
                      aria-label="info spinner example"
                      size="xl"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* modal for appointment */}
      <Modal show={openModal} size="md" onClose={onCloseModal} popup>
        <Modal.Header />
        <Modal.Body>
          <div className="space-y-6">
            <h3 className="text-xl font-medium text-gray-900 dark:text-white">
              <strong>Name: </strong>
              {appointment?.name}
            </h3>
            <div>
              <h4>
                <strong>Message: </strong>
                {appointment?.message?.trim() === "" ||
                appointment?.message === undefined
                  ? "No added message"
                  : appointment?.message}
              </h4>
            </div>
            <div>
              <h4>
                <strong>Phone: </strong> {appointment?.phone}
              </h4>
            </div>
            <div>
              <h4>
                <strong>Date Created: </strong>
                {formatDate(appointment?.createdAt)}
              </h4>
            </div>
            <div>
              <h4>
                <strong>Booking Date: </strong>
                {formatDate(appointment?.fromDate)} to{" "}
                {formatDate(appointment?.toDate)}
              </h4>
            </div>
            <div>
              <h4 className="capitalize">
                <strong>Status: </strong> {appointment?.status}
              </h4>
            </div>
            {appointment?.status === "pending" && (
              <div className="w-full flex justify-center gap-3">
                <Button
                  color="failure"
                  onClick={() =>
                    respondToAppointment(appointment.id, "cancelled")
                  }
                  disabled={loading2}
                >
                  Cancel
                </Button>
                <Button
                  color="success"
                  onClick={() =>
                    respondToAppointment(appointment.id, "completed")
                  }
                  disabled={loading2}
                >
                  Approve
                </Button>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>

      {/* modal for logout */}
      <Modal show={openModalOut} size="md" popup>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              {msg}
            </h3>
            <div className="flex justify-center gap-4">
              <Button color="failure" onClick={() => logOut()}>
                {"OK"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Home;

function formatDate(dateString) {
  const dateObj = new Date(dateString);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[dateObj.getMonth()];

  const desiredFormat = `${dateObj.getFullYear()}-${month}-${dateObj.getDate()}`;

  return desiredFormat;
}
