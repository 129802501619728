import React, { useContext, useState, useEffect } from "react";
import NavbarWithCTAButton from "../components/Nav";
import { Card, Spinner, Button, Modal, TextInput, Label } from "flowbite-react";
import { Store } from "../context/store";
import { useNavigate } from "react-router-dom";
import SideBar from "../components/Sidebar";
import apiRequest from "../utils/apiRequest";
import { HiOutlineExclamationCircle } from "react-icons/hi";

function Settings() {
  let store = useContext(Store);
  let [baseUrl] = store.url;
  let [msg, setMsg] = useState("");
  let [error, setError] = useState("");
  let [loading1, setLoading1] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [openModalOut, setOpenModalOut] = useState(false);
  const navigate = useNavigate();

  const changePassword = async () => {
    if (!confirmPassword || !oldPassword || !newPassword) {
      setError("Please fill all missing fields.");
      return;
    }

    if (oldPassword === newPassword) {
      setError("New password cannnot be the same as old password.");
      return;
    }

    if (confirmPassword !== newPassword) {
      setError("Password and Confirm Password do not match.");
      return;
    }

    if (!validatePassword(newPassword)) {
      setError(
        "Password must be minimun of 8 characters, including uppercase, lowercase, numbers, and special characters."
      );
      return;
    }
    const confirmed = window.confirm(
      "Are you sure you want to change password?"
    );
    if (confirmed) {
      setLoading1(true);
      try {
        let token = localStorage.getItem("royal-token");
        const url = `${baseUrl}/password/staff`;
        await apiRequest(
          url,
          "PUT",
          { oldPassword, newPassword, confirmPassword },
          token
        );
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setError("Password changed successfully.");
      } catch (error) {
        const unauthorizedErrors = [
          "Access Denied: Invalid or expired token. Please login again",
          "Access Denied: Please log in.",
          "Access Denied: Invalid Token.",
          "Access Denied: Invalid or expired token.",
          "Access Denied: Invalid or Expired Token.",
          "Access Denied: No token provided.",
        ];
        if (unauthorizedErrors.includes(error.message)) {
          setMsg("Session Expired!");
          setOpenModalOut(true);
          localStorage.removeItem("royal-token");
        } else {
          setError(error.message);
          console.log(error);
        }
      } finally {
        setLoading1(false);
      }
    }
  };

  let logOut = () => {
    navigate("/");
  };

  return (
    <>
      <title>Profile Settings</title>
      <meta
        name="description"
        content="Site Settings Page to the Royalmines Property Office"
      />
      <div className="container mx-auto">
        <NavbarWithCTAButton />

        <div className="container  fixed ">
          <div className="grid gap-4 grid-cols-6 md:grid-cols-5 mt-5">
            <SideBar />
            <div className="col-span-5 md:col-span-4" data-aos="flip-up">
              <div className="mb-4">
                <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                  Profile Settings
                </h5>
              </div>
              {!loading1 ? (
                <>
                  <Card className="rounded-xl my-4">
                    <h3>Change Password</h3>
                    <div className="flex justify-center items-center">
                      <Card className="w-3/4 md:w-1/2 card">
                        <p className="text-red-500 text-sm text-center">
                          {error}
                        </p>
                        <div className="flex max-w-md flex-col gap-4">
                          <div>
                            <div className="mb-2 block">
                              <Label
                                htmlFor="OldPassword"
                                value="Old Password"
                              />
                            </div>
                            <TextInput
                              id="OldPassword"
                              placeholder="Old Password"
                              required
                              name="Old Password"
                              value={oldPassword}
                              type="password"
                              onChange={(e) => setOldPassword(e.target.value)}
                            />
                          </div>
                          <div>
                            <div className="mb-2 block">
                              <Label
                                htmlFor="NewPassword"
                                value="New Password"
                              />
                            </div>
                            <TextInput
                              id="NewPassword"
                              placeholder="Enter New Password"
                              required
                              name="New Password"
                              value={newPassword}
                              type="password"
                              onChange={(e) => setNewPassword(e.target.value)}
                            />
                          </div>
                          <div>
                            <div className="mb-2 block">
                              <Label
                                htmlFor="ConfirmPassword"
                                value="Confirm New Password"
                              />
                            </div>
                            <TextInput
                              id="ConfirmPassword"
                              placeholder="Confirm New Password"
                              required
                              name="Confirm New Password"
                              value={confirmPassword}
                              type="password"
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                            />
                          </div>
                          <Button
                            disabled={loading1}
                            className="bg-#6eb5ff"
                            onClick={() => changePassword()}
                          >
                            {loading1 ? "Loading..." : "Change Password"}
                          </Button>
                        </div>
                      </Card>
                    </div>
                  </Card>
                </>
              ) : (
                <div className="flex justify-center items-center h-[100%]">
                  <Spinner
                    style={{ color: "#0078e7" }}
                    aria-label="info spinner example"
                    size="xl"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* modal for logout */}
      <Modal show={openModalOut} size="md" popup>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              {msg}
            </h3>
            <div className="flex justify-center gap-4">
              <Button color="failure" onClick={() => logOut()}>
                {"OK"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Settings;

function validatePassword(password) {
  const re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{8,}$/;
  return re.test(password);
}
