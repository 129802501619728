import React, { createContext, useState } from "react";
export const Store = createContext();
const StoreContext = ({ children }) => {
  // let [baseUrl] = useState("http://localhost:7070/api/v1");
  let [baseUrl] = useState("https://royal-mines-backend.onrender.com/api/v1");
  // let [baseUrl] = useState("https://masjid.zumaconsults.com/api/v1");
  let [capexArray, setCapexArray] = useState({ rows: [] });
  let [opexArray, setOpexArray] = useState({ rows: [] });
  let [voucherArray, setVouchersArray] = useState({ rows: [] });
  let [invoice, setInvoice] = useState({ rows: [] });
  let [loggedInUser, setLoggedInUser] = useState({});

  let states = {
    url: [baseUrl],
    capexArray: [capexArray, setCapexArray],
    opexArray: [opexArray, setOpexArray],
    voucherArray: [voucherArray, setVouchersArray],
    invoice: [invoice, setInvoice],
    loggedInUser: [loggedInUser, setLoggedInUser],
  };
  return <Store.Provider value={states}>{children}</Store.Provider>;
};
export default StoreContext;
