import React, { useContext, useState, useEffect } from "react";
import NavbarWithCTAButton from "../components/Nav";
import {
  Card,
  Spinner,
  Button,
  Modal,
  Breadcrumb,
  Tabs,
  Label,
  Textarea,
} from "flowbite-react";
import { Store } from "../context/store";
import SideBar from "../components/Sidebar";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate, Link, useParams } from "react-router-dom";
import apiRequest from "../utils/apiRequest";
import { HiOutlineExclamationCircle } from "react-icons/hi";

function SingleProperty() {
  let store = useContext(Store);
  let [baseUrl] = store.url;
  let [msg, setMsg] = useState([]);
  let [loading, setLoading] = useState(false);
  let [loading1, setLoading1] = useState(false);
  let [project, setProject] = useState({});
  const [openModalOut, setOpenModalOut] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  let [comment, setComment] = useState("");
  let [error, setError] = useState("");

  useEffect(() => {
    Promise.all([loadProject()])
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  const loadProject = async () => {
    setLoading(true);
    try {
      let token = localStorage.getItem("royal-token");
      const url = `${baseUrl}/property/${id}`;
      const { data } = await apiRequest(url, "GET", null, token);
      setProject(data);
    } catch (error) {
      const unauthorizedErrors = [
        "Access Denied: Invalid or expired token. Please login again",
        "Access Denied: Please log in.",
        "Access Denied: Invalid Token.",
        "Access Denied: Invalid or expired token.",
        "Access Denied: Invalid or Expired Token.",
        "Access Denied: No token provided.",
      ];
      if (unauthorizedErrors.includes(error.message)) {
        setMsg("Session Expired!");
        setOpenModalOut(true);
        localStorage.removeItem("royal-token");
      } else {
        console.log(error);
      }
    }
  };

  const sendComment = async () => {
    const body = { comment: comment };
    const confirmed = window.confirm(
      `Are you sure you want to send a message?`
    );
    if (confirmed) {
      setLoading1(true);
      try {
        let token = localStorage.getItem("royal-token");
        const url = `${baseUrl}/comment/property/${id}`;
        await apiRequest(url, "PUT", body, token);
        setError("Message sent.");
        setComment("");
        loadProject();
      } catch (error) {
        const unauthorizedErrors = [
          "Access Denied: Invalid or expired token. Please login again",
          "Access Denied: Please log in.",
          "Access Denied: Invalid Token.",
          "Access Denied: Invalid or expired token.",
          "Access Denied: Invalid or Expired Token.",
          "Access Denied: No token provided.",
        ];
        if (unauthorizedErrors.includes(error.message)) {
          setMsg("Session Expired!");
          setOpenModalOut(true);
          localStorage.removeItem("royal-token");
        } else {
          console.log(error);
        }
      } finally {
        setLoading1(false);
        setLoading(false);
      }
    }
  };

  let logOut = () => {
    navigate("/");
  };

  return (
    <>
      <title>Property / {project.title}</title>
      <meta
        name="description"
        content={`${project.title} Page to the Royalmines Property Office`}
      />
      <div className="container mx-auto">
        <NavbarWithCTAButton />
        {/* {loading && (
          <div className="flex justify-center items-center h-[80vh]">
            <Spinner color="info" aria-label="info spinner example" size="xl" />
          </div>
        )} */}

        {/* {!loading && ( */}
          <div className="container-fluid mx-auto">
            <div className="grid gap-4 grid-cols-6 md:grid-cols-5 mt-5">
              <SideBar projects="actives" />
              <div className="col-span-5 md:col-span-4" data-aos="zoom-in">
                <div className="mb-4">
                  <Breadcrumb>
                    <Link to="/properties">
                      <Breadcrumb.Item>
                        <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                          Property
                        </h5>
                      </Breadcrumb.Item>
                    </Link>
                    <Breadcrumb.Item>{project?.title}</Breadcrumb.Item>
                  </Breadcrumb>
                  <div className="flex flex-wrap gap-2 mt-5 justify-end">
                    <Button as={Link} to="/properties" className="bg-#6eb5ff">
                      <IoMdArrowBack className="me-2" />
                      Go Back
                    </Button>
                  </div>
                </div>
                {!loading ? (
                  <>
                    <div className="">
                      <Tabs.Group
                        aria-label="Tabs with underline"
                        style="fullWidth"
                        id="tab"
                      >
                        <Tabs.Item active title="Details">
                          <Card>
                            <div className="flex justify-around align-middle items-center pb-1 flex-wrap gap-5">
                              {project?.images?.map((a, b) => {
                                return (
                                  <img
                                    className="object-cover w-full md:w-1/4 rounded-xl"
                                    src={a}
                                    alt={`image of ${project.firstName} ${project.lastName} `}
                                  />
                                );
                              })}
                              <div className="flex flex-col">
                                <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">
                                  {project.title}
                                </h5>
                                <span className="text-sm text-gray-500 dark:text-gray-400">
                                  <strong>Price:</strong>{" "}
                                  {project.price?.toLocaleString()}
                                </span>
                                <span className="text-sm text-gray-500 dark:text-gray-400">
                                  <strong>Video:</strong>{" "}
                                  {project?.videos && project?.videos[0]}
                                </span>
                                <span className="text-sm text-gray-500 dark:text-gray-400">
                                  <strong>Quantity:</strong> {project.quantity}
                                </span>
                                <span className="text-sm text-gray-500 dark:text-gray-400">
                                  <strong>Add On:</strong>{" "}
                                  {formatDate(project.createdAt)}
                                </span>
                                <span className="text-sm text-gray-500 dark:text-gray-400">
                                  <strong>Status:</strong> {project.status}
                                </span>
                                <span className="text-sm text-gray-500 dark:text-gray-400">
                                  <strong>Document Type:</strong>{" "}
                                  {project.documentType}
                                </span>
                              </div>
                            </div>

                            <div>
                              <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                                <li className="py-3 sm:py-4">
                                  <div className="flex items-center space-x-4">
                                    <div className="min-w-0 flex gap-1 flex-1">
                                      <p className=" text-sm font-medium text-gray-900 dark:text-white">
                                        Address:
                                      </p>
                                      <p className="capitalize text-sm text-gray-500 dark:text-gray-400">
                                        {project?.address}
                                      </p>
                                    </div>
                                  </div>
                                </li>

                                <li className="py-3 sm:py-4">
                                  <div className="flex items-center space-x-4">
                                    <div className="min-w-0 flex gap-1 flex-1">
                                      <p className=" text-sm font-medium text-gray-900 dark:text-white">
                                        Dining:
                                      </p>
                                      <p className="capitalize text-sm text-gray-500 dark:text-gray-400">
                                        {project?.dining}
                                      </p>
                                    </div>
                                  </div>
                                </li>

                                <li className="py-3 sm:py-4">
                                  <div className="flex items-center space-x-4">
                                    <div className="min-w-0 flex gap-1 flex-1">
                                      <p className=" text-sm font-medium text-gray-900 dark:text-white">
                                        Features:
                                      </p>
                                      <ul className="capitalize  text-sm text-gray-500 dark:text-gray-400">
                                        {project.features?.map((a, b) => {
                                          return <li>{a}</li>;
                                        })}
                                      </ul>
                                    </div>
                                  </div>
                                </li>

                                <li className="py-3 sm:py-4">
                                  <div className="flex items-center space-x-4">
                                    <div className="min-w-0 flex gap-1 flex-1">
                                      <p className=" text-sm font-medium text-gray-900 dark:text-white">
                                        Landmarks:
                                      </p>
                                      <ul className="capitalize  text-sm text-gray-500 dark:text-gray-400">
                                        {project.landmarks?.map((a, b) => {
                                          return <li>{a}</li>;
                                        })}
                                      </ul>
                                    </div>
                                  </div>
                                </li>

                                <li className="py-3 sm:py-4">
                                  <div className="flex items-center space-x-4">
                                    <div className="min-w-0 flex gap-1 flex-1">
                                      <p className=" text-sm font-medium text-gray-900 dark:text-white">
                                        List On Website:
                                      </p>
                                      <p className="capitalize  text-sm text-gray-500 dark:text-gray-400">
                                        {project.list ? "Yes" : "No"}
                                      </p>
                                    </div>
                                  </div>
                                </li>
                                <li className="py-3 sm:py-4">
                                  <div className="flex items-center space-x-4">
                                    <div className="min-w-0 flex gap-1">
                                      <p className=" text-sm font-medium text-gray-900 dark:text-white">
                                        Number of Sitting Room:
                                      </p>
                                      <p className="capitalize text-sm text-gray-500 dark:text-gray-400">
                                        {project.sittingRoom}
                                      </p>
                                    </div>
                                  </div>
                                </li>

                                <li className="py-3 sm:py-4">
                                  <div className="flex items-center space-x-4">
                                    <div className="min-w-0 flex gap-1">
                                      <p className=" text-sm font-medium text-gray-900 dark:text-white">
                                        Number of Bedrooms:
                                      </p>
                                      <p className="capitalize text-sm text-gray-500 dark:text-gray-400">
                                        {project.bedrooms}
                                      </p>
                                    </div>
                                  </div>
                                </li>

                                <li className="py-3 sm:py-4">
                                  <div className="flex items-center space-x-4">
                                    <div className="min-w-0 flex gap-1">
                                      <p className=" text-sm font-medium text-gray-900 dark:text-white">
                                        Number of Toilets:
                                      </p>
                                      <p className="capitalize text-sm text-gray-500 dark:text-gray-400">
                                        {project.toilet}
                                      </p>
                                    </div>
                                  </div>
                                </li>

                                <li className="py-3 sm:py-4">
                                  <div className="flex items-center space-x-4">
                                    <div className="min-w-0 flex gap-1">
                                      <p className=" text-sm font-medium text-gray-900 dark:text-white">
                                        Number of Bathrooms:
                                      </p>
                                      <p className="capitalize text-sm text-gray-500 dark:text-gray-400">
                                        {project.bathroom}
                                      </p>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>

                            <div className="flex flex-col justify-center align-middle items-center pb-1">
                              <h5 className="font-bold my-4">Plans</h5>

                              <div className="flex justify-evenly align-middle items-center pb-1 flex-wrap gap-5">
                                {project?.plans?.length > 0 ? (
                                  project.plans?.map((a, b) => {
                                    return (
                                      <>
                                        <img
                                          className="object-cover w-full md:w-1/4  rounded-xl"
                                          src={a}
                                          alt={`image of ${project.firstName} ${project.lastName} `}
                                        />
                                      </>
                                    );
                                  })
                                ) : (
                                  <h3>No plan images added yet.</h3>
                                )}
                              </div>
                            </div>
                          </Card>
                        </Tabs.Item>

                        <Tabs.Item title="Messages To Subscribers">
                          <div className="flex justify-center items-center">
                            <Card className="w-3/4 md:w-1/2 card">
                              <p className="text-red-500 text-sm text-center">
                                {error}
                              </p>
                              <div className="flex max-w-md flex-col gap-4">
                                <div>
                                  <div className="mb-2 block">
                                    <Label
                                      htmlFor="username1"
                                      value="Your Message"
                                    />
                                  </div>
                                  <Textarea
                                    id="comment"
                                    placeholder="Enter Message for the Subscribers of this property..."
                                    required
                                    name="comment"
                                    value={comment}
                                    rows={4}
                                    onChange={(e) => setComment(e.target.value)}
                                  />
                                </div>
                                <Button
                                  disabled={loading1}
                                  className="bg-#6eb5ff"
                                  onClick={() => sendComment()}
                                >
                                  {loading1 ? "Loading..." : "Send"}
                                </Button>
                              </div>
                            </Card>
                          </div>

                          <div className="py-3 sm:py-4">
                            <h3 className="text-lg font-bold text-center my-5">
                              Message History
                            </h3>
                            <Card className="flex space-x-4">
                              <div className="flex flex-col gap-2 min-w-0 flex-1">
                                {project?.comments?.length > 0 ? (
                                  project.comments
                                    .slice()
                                    .reverse()
                                    .map((e, i) => (
                                      <div
                                        key={i}
                                        className="flex flex-col items-start px-4 py-2 rounded-lg bg-gray-100 dark:bg-gray-700"
                                      >
                                        <span className="text-gray-400 text-sm mt-1">
                                          {formatDate(e.time)}
                                        </span>
                                        <p className="text-base text-gray-700">
                                          {e.comment}
                                        </p>
                                      </div>
                                    ))
                                ) : (
                                  <p>
                                    No Message sent to the subscribers of this
                                    property.
                                  </p>
                                )}
                              </div>
                            </Card>
                          </div>
                        </Tabs.Item>
                      </Tabs.Group>
                    </div>
                  </>
                ) : (
                  <div className="flex justify-center items-center h-[100%]">
                    <Spinner
                      style={{ color: "#0078e7" }}
                      aria-label="info spinner example"
                      size="xl"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        {/* )} */}
      </div>

      {/* modal for logout */}
      <Modal show={openModalOut} size="md" popup>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              {msg}
            </h3>
            <div className="flex justify-center gap-4">
              <Button color="failure" onClick={() => logOut()}>
                {"OK"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SingleProperty;

function formatDate(dateString) {
  const dateObj = new Date(dateString);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[dateObj.getMonth()];

  const desiredFormat = `${dateObj.getFullYear()}-${month}-${dateObj.getDate()}`;

  return desiredFormat;
}
