import React, { useContext, useState, useEffect } from "react";
import NavbarWithCTAButton from "../components/Nav";
import {
  Card,
  Spinner,
  Table,
  Banner,
  Button,
  Label,
  Modal,
  TextInput,
  FileInput,
  Breadcrumb,
} from "flowbite-react";
import { HiX } from "react-icons/hi";
import { MdAnnouncement } from "react-icons/md";
import { Store } from "../context/store";
import { Link } from "react-router-dom";
import SideBar from "../components/Sidebar";
import { FiFilter } from "react-icons/fi";
import { IoMdAddCircleOutline } from "react-icons/io";
import { HiHome } from "react-icons/hi";

function Folders() {
  let store = useContext(Store);
  let [baseUrl] = store.url;
  let [msg, setMsg] = useState([]);
  let [loading, setLoading] = useState(true);
  let [, setError] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [email, setEmail] = useState("");

  function onCloseModal() {
    setOpenModal(false);
    setEmail("");
  }

  useEffect(() => {
    Promise.all([loadUser()])
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  

  const loadUser = async () => {
    try {
      const token = localStorage.getItem("inbox");
      const url = `${baseUrl}/token`;
      const response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          "mailing-user": token,
        },
        method: "GET",
      });
      if (!response.ok) {
        throw new Error("Failed to fetch user");
      }
      const { data } = await response.json();
      setEmail(data.email);
    } catch (error) {
      setError("An error occurred. Please try again.");
      throw error;
    }
  };

  return (
    <>
      <title>Folders</title>
      <meta
        name="description"
        content="Folders Page to the Royalmines Property Office"
      />
      <div className="container mx-auto">
        <NavbarWithCTAButton />
        {/* {loading && (
          <div className="flex justify-center items-center h-[80vh]">
            <Spinner color="info" aria-label="info spinner example" size="xl" />
          </div>
        )} */}

        {/* {!loading && ( */}
          <div className="container-fluid mx-auto">
            {/* <Banner>
              <div className="flex w-full justify-between border-b border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700 mt-3">
                <div className="mx-auto flex items-center">
                  {!userEmail ? (
                    <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                      <MdAnnouncement className="mr-4 h-4 w-4" />
                      <span className="[&_p]:inline">
                        Welcome <p className="font-bold">Abubakar Ahmed</p>. You
                        have{" "}
                        <p className="inline font-medium underline decoration-solid underline-offset-2 hover:no-underline dark:text-cyan-500">
                          {unread} unread messages
                        </p>{" "}
                        out of {msg.length}
                      </span>
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <Banner.CollapseButton
                  color="gray"
                  className="border-0 bg-transparent text-gray-500 dark:text-gray-400"
                >
                  <HiX className="h-4 w-4" />
                </Banner.CollapseButton>
              </div>
            </Banner> */}

            <div className="grid gap-4 grid-cols-6 md:grid-cols-5 mt-5">
              <SideBar documents="actives" />
              <div
                className="col-span-5 md:col-span-4"
                data-aos="fade-down-left"
              >
                <div className="mb-4">
                  <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                    Documents
                  </h5>
                  <div className="flex flex-wrap gap-2 mt-5 justify-between align-bottom">
                    <Breadcrumb aria-label="Default breadcrumb example" className="mt-8">
                      <Breadcrumb.Item href="#" icon={HiHome}>
                        Home
                      </Breadcrumb.Item>
                      <Breadcrumb.Item href="#">Mortagage</Breadcrumb.Item>
                      <Breadcrumb.Item>Batch 2</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="flex flex-wrap gap-2 mt-5 justify-between align-middle">
                      <Button
                        className="bg-#6eb5ff"
                        onClick={() => setOpenModal(true)}
                      >
                        {" "}
                        <FiFilter className="me-2" />
                        Filter
                      </Button>
                      <Button
                        color="success"
                        onClick={() => setOpenModal(true)}
                      >
                        <IoMdAddCircleOutline className="me-2" />
                        New Folder
                      </Button>
                      <Button
                        color="success"
                        onClick={() => setOpenModal(true)}
                      >
                        <IoMdAddCircleOutline className="me-2" />
                        New File
                      </Button>
                    </div>
                  </div>
                </div>
                <Card className="rounded-xl my-4">
                  <div className="overflow-x-auto">
                    <Table hoverable>
                      <Table.Head>
                        <Table.HeadCell>Subject</Table.HeadCell>
                        <Table.HeadCell>Content</Table.HeadCell>
                        <Table.HeadCell>
                          <span className="sr-only">Edit</span>
                        </Table.HeadCell>
                      </Table.Head>

                      <Table.Body className="divide-y">
                        {msg.length === 0 ? (
                          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                            <Table.Cell
                              colSpan={3}
                              className="text-center py-4"
                            >
                              No documents added yet.
                            </Table.Cell>
                          </Table.Row>
                        ) : (
                          msg.map((e, i) => (
                            <Table.Row
                              key={i}
                              className={`bg-white dark:border-gray-700 dark:bg-gray-800 ${
                                e.isRead ? "read-msgs " : ""
                              }`}
                            >
                              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                {e.subject}
                              </Table.Cell>
                              <Table.Cell className="text-ellipsis">
                                {e.content}
                              </Table.Cell>
                              <Table.Cell>
                                <Link
                                  className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                                  to={`/message/${e.id}`}
                                >
                                  View
                                </Link>
                              </Table.Cell>
                            </Table.Row>
                          ))
                        )}
                      </Table.Body>
                    </Table>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        {/* )} */}
      </div>

      {/* modal for new file */}
      <Modal show={openModal} size="md" onClose={onCloseModal} popup>
        <Modal.Header />
        <Modal.Body>
          <div className="space-y-6">
            <h3 className="text-xl font-medium text-gray-900 dark:text-white">
              Add New File
            </h3>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="email" value="Your email" />
              </div>
              <TextInput
                id="text"
                placeholder="File Name"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                required
              />
            </div>
            <div id="fileUpload" className="max-w-md">
              <div className="mb-2 block">
                <Label htmlFor="file" value="Upload file" />
              </div>
              <FileInput
                id="file"
                helperText="You can select multiple files"
                multiple
              />
            </div>
            <div className="w-full flex justify-center">
              <Button color="success">Save</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* modal for search and filter */}
      <Modal show={openModal} size="md" onClose={onCloseModal} popup>
        <Modal.Header />
        <Modal.Body>
          <div className="space-y-6">
            <h3 className="text-xl font-medium text-gray-900 dark:text-white">
              Filter and Search
            </h3>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="email" value="Your email" />
              </div>
              <TextInput
                id="text"
                placeholder="File Name"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                required
              />
            </div>
            <div id="fileUpload" className="max-w-md">
              <div className="mb-2 block">
                <Label htmlFor="file" value="Upload file" />
              </div>
              <FileInput
                id="file"
                helperText="You can select multiple files"
                multiple
              />
            </div>
            <div className="w-full flex justify-center">
              <Button color="success">Save</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Folders;
