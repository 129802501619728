import React, { useContext, useState } from "react";
import NavbarWithCTAButton from "../components/Nav";
import {
  Card,
  Spinner,
  Table,
  Button,
  Label,
  TextInput,
  Textarea,
} from "flowbite-react";
import { Store } from "../context/store";
import SideBar from "../components/Sidebar";
import { IoMdArrowBack, IoMdArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

function Receipts() {
  let store = useContext(Store);
  let [invoice, setInvoice] = store.invoice;
  let [loading] = useState(false);
  const [secondCard, setSecondCard] = useState(false);
  const [invoiceBody, setInvoiceBody] = useState({});

  const pushToInvoice = (e) => {
    invoice.rows.push(e);
    setInvoiceBody({
      item: "",
      description: "",
      rate: "",
      amount: "",
      ordered: "",
    });
  };

  return (
    <>
      <title>Receipt</title>
      <meta
        name="description"
        content="Home Page to the Royalmines Property Office"
      />
      <div className="container mx-auto">
        <NavbarWithCTAButton />

        <div className="container-fluid mx-auto">
          <div className="grid gap-4 grid-cols-6 md:grid-cols-5 mt-5">
            <SideBar receipts="actives" />

            {!secondCard && (
              <div className="col-span-5 md:col-span-4" data-aos="slide-left">
                <Card className="w-1/2 mx-auto">
                  <div className="flex max-w-md flex-col gap-4">
                    {/* date and serial no */}
                    <div className="grid grid-cols-2 gap-4">
                      <div className="col-span-1">
                        <div className="mb-2 block">
                          <Label htmlFor="date1" value="Date" />
                        </div>
                        <TextInput
                          id="date1"
                          type="date"
                          required
                          value={invoice.date}
                          onChange={(e) =>
                            setInvoice({ ...invoice, date: e.target.value })
                          }
                        />
                      </div>
                      <div className="col-span-1">
                        <div className="mb-2 block">
                          <Label htmlFor="serial1" value="Serial Number" />
                        </div>
                        <TextInput
                          id="serial1"
                          type="number"
                          placeholder="Enter Serial Number"
                          required
                          min={1}
                          value={invoice.serial}
                          onChange={(e) =>
                            setInvoice({
                              ...invoice,
                              serial: parseInt(e.target.value),
                            })
                          }
                        />
                      </div>
                    </div>

                    {/* p.o no and project */}
                    <div className="grid grid-cols-2 gap-4">
                      <div className="col-span-1">
                        <div className="mb-2 block">
                          <Label htmlFor="po1" value="P.O No." />
                        </div>
                        <TextInput
                          id="po1"
                          type="text"
                          placeholder="Enter P.O Number"
                          value={invoice.po}
                          onChange={(e) =>
                            setInvoice({ ...invoice, po: e.target.value })
                          }
                        />
                      </div>
                      <div className="col-span-1">
                        <div className="mb-2 block">
                          <Label htmlFor="project1" value="Project" />
                        </div>
                        <TextInput
                          id="project1"
                          type="text"
                          placeholder="Enter Project"
                          value={invoice.project}
                          onChange={(e) =>
                            setInvoice({
                              ...invoice,
                              project: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    {/* name and address */}
                    <div>
                      <div>
                        <div className="mb-2 block">
                          <Label htmlFor="name1" value="Name on Invoice" />
                        </div>
                        <TextInput
                          id="name1"
                          type="text"
                          placeholder="Enter name"
                          required
                          value={invoice.invoiceName}
                          onChange={(e) =>
                            setInvoice({
                              ...invoice,
                              invoiceName: e.target.value,
                            })
                          }
                        />
                      </div>

                      <div className="max-w-md">
                        <div className="mb-2 block">
                          <Label htmlFor="address1" value="Address" />
                        </div>
                        <Textarea
                          id="address1"
                          placeholder="Enter address"
                          required
                          rows={4}
                          value={invoice.invoiceAddress}
                          onChange={(e) =>
                            setInvoice({
                              ...invoice,
                              invoiceAddress: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    {/* ship to and address */}
                    <div>
                      <div>
                        <div className="mb-2 block">
                          <Label htmlFor="name2" value="Name to ship to" />
                        </div>
                        <TextInput
                          id="name2"
                          type="text"
                          placeholder="Enter name"
                          value={invoice.shipName}
                          onChange={(e) =>
                            setInvoice({
                              ...invoice,
                              shipName: e.target.value,
                            })
                          }
                        />
                      </div>

                      <div className="max-w-md">
                        <div className="mb-2 block">
                          <Label
                            htmlFor="address2"
                            value="Address to ship to"
                          />
                        </div>
                        <Textarea
                          id="address2"
                          placeholder="Enter address"
                          required
                          rows={4}
                          value={invoice.shipAddress}
                          onChange={(e) =>
                            setInvoice({
                              ...invoice,
                              shipAddress: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <Button type="button" onClick={() => setSecondCard(true)}>
                      Next
                    </Button>
                  </div>
                </Card>
              </div>
            )}

            {/* add items to receipt */}
            {secondCard && (
              <div className="col-span-5 md:col-span-4" data-aos="slide-right">
                <div className="flex flex-wrap gap-2 mt-5 justify-between">
                  <Button color="info" onClick={() => setSecondCard(false)}>
                    <IoMdArrowBack className="me-2" />
                    Go Back
                  </Button>

                  <Button color="info" as={Link} to="/preview">
                    Preview Receipt
                    <IoMdArrowForward className="ms-2" />
                  </Button>
                </div>
                <Card className="w-1/2 mx-auto">
                  <div className="flex max-w-md flex-col gap-4">
                    {/* item */}
                    <div>
                      <div className="mb-2">
                        <div className="mb-2 block">
                          <Label htmlFor="item1" value="Item" />
                        </div>
                        <TextInput
                          id="item1"
                          type="text"
                          placeholder="Enter item"
                          value={invoiceBody.item}
                          onChange={(e) =>
                            setInvoiceBody({
                              ...invoiceBody,
                              item: e.target.value,
                            })
                          }
                        />
                      </div>

                      <div className="mb-2">
                        <div className="mb-2 block">
                          <Label htmlFor="desc1" value="Description" />
                        </div>
                        <TextInput
                          id="desc1"
                          type="text"
                          placeholder="Enter Description"
                          value={invoiceBody.description}
                          onChange={(e) =>
                            setInvoiceBody({
                              ...invoiceBody,
                              description: e.target.value,
                            })
                          }
                        />
                      </div>

                      <div className="mb-2">
                        <div className="mb-2 block">
                          <Label htmlFor="Ordered1" value="Ordered" />
                        </div>
                        <TextInput
                          id="Ordered1"
                          type="text"
                          placeholder="Enter Ordered"
                          value={invoiceBody.ordered}
                          onChange={(e) =>
                            setInvoiceBody({
                              ...invoiceBody,
                              ordered: e.target.value,
                            })
                          }
                        />
                      </div>

                      <div className="grid grid-cols-2 gap-4 mb-2">
                        <div className="col-span-1">
                          <div className="mb-2 block">
                            <Label htmlFor="Rate1" value="Rate" />
                          </div>
                          <TextInput
                            id="Rate1"
                            type="number"
                            placeholder="Enter Rate"
                            min={0}
                            value={invoiceBody.rate}
                            onChange={(e) =>
                              setInvoiceBody({
                                ...invoiceBody,
                                rate: parseInt(e.target.value),
                              })
                            }
                          />
                        </div>

                        <div className="col-span-1">
                          <div className="mb-2 block">
                            <Label htmlFor="Amount1" value="Amount" />
                          </div>
                          <TextInput
                            id="Amount1"
                            type="number"
                            placeholder="Enter Amount"
                            min={0}
                            value={invoiceBody.amount}
                            onChange={(e) =>
                              setInvoiceBody({
                                ...invoiceBody,
                                amount: parseInt(e.target.value),
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <Button
                      type="button"
                      onClick={() => pushToInvoice(invoiceBody)}
                    >
                      Add to Invoice
                    </Button>
                  </div>
                </Card>
              </div>
            )}
          </div>
        </div>

        <>
          {secondCard && (
            <Card className="rounded-xl my-4">
              <div className="">
                <div className="overflow-x-auto">
                  <Table className="border-2 border-black text-xs">
                    <Table.Head>
                      <Table.HeadCell>Item</Table.HeadCell>
                      <Table.HeadCell colSpan={3}>Description</Table.HeadCell>
                      <Table.HeadCell>Ordered</Table.HeadCell>
                      <Table.HeadCell>Rate</Table.HeadCell>
                      <Table.HeadCell>Amount</Table.HeadCell>
                    </Table.Head>
                    {invoice?.rows?.map((e, i) => {
                      return (
                        <Table.Body className="divide-y divide-x text-black">
                          <Table.Row>
                            <Table.Cell className="whitespace-nowrap text-ellipsis">
                              {e.item}
                            </Table.Cell>
                            <Table.Cell colSpan={3}>{e.description}</Table.Cell>
                            <Table.Cell className="text-ellipsis">
                              {e.ordered}
                            </Table.Cell>
                            <Table.Cell>{e.rate}</Table.Cell>
                            <Table.Cell>{e.amount}</Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      );
                    })}
                  </Table>
                </div>
              </div>
            </Card>
          )}
        </>
      </div>
    </>
  );
}

export default Receipts;
