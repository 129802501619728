import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./assets/css/style.css";
import "./assets/css/login.css";
import "./assets/css/sidebar.css";
import "./assets/css/receipt.css";
import Home from "./pages/Home";
import Login from "./pages/Login";
import StoreContext from "./context/store";
import { useState, useEffect } from "react";
import { Toast } from "flowbite-react";
import { HiX } from "react-icons/hi";
import NotFound from "./pages/NotFound";
import Customers from "./pages/Customers";
import Tasks from "./pages/Tasks";
import Aos from "aos";
import "aos/dist/aos.css";
import Users from "./pages/Staff";
import Projects from "./pages/Projects";
import PreviewReceipts from "./pages/PreviewReceipt";
import Folders from "./pages/Folders";
import Message from "./pages/Message";
import Role from "./pages/Roles";
import Capex from "./pages/Capex";
import Opex from "./pages/Opex";
import Vouchers from "./pages/Vouchers";
import Receipts from "./pages/Receipt";
import SingleCustomer from "./pages/SingleCustomer";
import SingleProperty from "./pages/SingleProperties";
import SiteSettings from "./pages/SiteSettings";
import Settings from "./pages/Settings";

function App() {
  let [error, setError] = useState("");
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  // const UploadRouteGuard = ({ element }) => {
  //   if (
  //     !localStorage.getItem("inbox") ||
  //     localStorage.getItem("inbox") === "undefined"
  //   ) {
  //     setError(
  //       <Toast>
  //         <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-red-100 text-red-500 dark:bg-red-800 dark:text-red-200">
  //           <HiX className="h-5 w-5" />
  //         </div>
  //         <div className="ml-3 text-sm font-normal">
  //           Access denied. Please login.
  //         </div>
  //         <Toast.Toggle />
  //       </Toast>
  //     );
  //     return <Navigate to="/" />;
  //   }
  //   return element;
  // };
  return (
    <StoreContext>
      <Router>
        <Routes>
          <Route path="/" element={<Login error={error} />} />
          {/* <Route
            path="/dashboard"
            element={<UploadRouteGuard element={<Home />} />}
          />
          <Route
            path="/message/:id"
            element={<UploadRouteGuard element={<Message />} />}
          /> */}
          <Route path="/appointments" element={<Home />} />
          <Route path="/messages" element={<Message />} />
          <Route path="/clients" element={<Customers />} />
          <Route path="/tasks" element={<Tasks />} />
          <Route path="/staff" element={<Users />} />
          <Route path="/properties" element={<Projects />} />
          <Route path="/preview" element={<PreviewReceipts />} />
          <Route path="/documents" element={<Folders />} />
          <Route path="/roles" element={<Role />} />
          <Route path="/capex" element={<Capex />} />
          <Route path="/opex" element={<Opex />} />
          <Route path="/vouchers" element={<Vouchers />} />
          <Route path="/receipts" element={<Receipts />} />
          <Route path="/client/:id" element={<SingleCustomer />} />
          <Route path="/property/:id" element={<SingleProperty />} />
          <Route path="/site-settings" element={<SiteSettings />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </StoreContext>
  );
}

export default App;
