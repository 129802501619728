import { Sidebar } from "flowbite-react";
import { HiChartPie } from "react-icons/hi";
import {
  MdPeople,
  MdOutlineTask,
  MdOutlineTaskAlt,
  MdSettings,
} from "react-icons/md";
import { FaPeopleGroup } from "react-icons/fa6";
import { FaBriefcase, FaWpforms } from "react-icons/fa";
import { GrTask, GrProjects } from "react-icons/gr";
import { IoDocumentAttachOutline } from "react-icons/io5";
import { TbReport } from "react-icons/tb";
import { Link } from "react-router-dom";
import { Store } from "../context/store";
import { useContext } from "react";

export default function SideBar(props) {
  let store = useContext(Store);
  let [loggedInUser] = store.loggedInUser;
  return (
    <Sidebar
      aria-label="Default sidebar example"
      className="col-span-1 w-[50px] md:w-full side-bar h-fit"
    >
      <Sidebar.Items>
        <Sidebar.ItemGroup>
          {loggedInUser?.role?.permissions?.appointment?.read && (
            <Sidebar.Collapse
              className={props.dashboard}
              icon={HiChartPie}
              label="Notifications"
            >
              <Sidebar.Item
                className={props.appointments}
                as={Link}
                to="/appointments"
              >
                Appointments
              </Sidebar.Item>
              <Sidebar.Item className={props.message} as={Link} to="/messages">
                Messages
              </Sidebar.Item>
            </Sidebar.Collapse>
          )}

          {loggedInUser?.role?.permissions?.clients?.read && (
            <Sidebar.Item
              className={props.customers}
              as={Link}
              to="/clients"
              icon={FaPeopleGroup}
              labelColor="dark"
            >
              <span className="hidden md:block">Clients</span>
            </Sidebar.Item>
          )}

          <Sidebar.Item
            className={props.tasks}
            as={Link}
            to="/tasks"
            icon={GrTask}
          >
            <span className="hidden md:block">Tasks</span>
          </Sidebar.Item>

          {loggedInUser?.role?.permissions?.staff?.read && (
            <Sidebar.Collapse
              className={props.users}
              icon={HiChartPie}
              label="Employees"
            >
              <Sidebar.Item
                className={props.staff}
                as={Link}
                to="/staff"
                icon={MdPeople}
              >
                <span className="hidden md:block">Staff</span>
              </Sidebar.Item>
              {/* <Sidebar.Item
                className={props.roles}
                as={Link}
                to="/roles"
                icon={FaBriefcase}
              >
                <span className="hidden md:block">Roles</span>
              </Sidebar.Item> */}
            </Sidebar.Collapse>
          )}

          {loggedInUser?.role?.permissions?.expenditure?.read && (
            <Sidebar.Collapse
              className={props.expenditure}
              icon={TbReport}
              label="Expenditures"
            >
              <Sidebar.Item
                className={props.capex}
                as={Link}
                to="/capex"
                icon={MdOutlineTask}
              >
                <span className="hidden md:block">CapEx</span>
              </Sidebar.Item>
              <Sidebar.Item
                className={props.opex}
                as={Link}
                to="/opex"
                icon={MdOutlineTaskAlt}
              >
                <span className="hidden md:block">OpEx</span>
              </Sidebar.Item>
              {loggedInUser?.role?.permissions?.vouchers?.read && (
                <Sidebar.Item
                  className={props.vouchers}
                  as={Link}
                  to="/vouchers"
                  icon={FaWpforms}
                >
                  <span className="hidden md:block">Vouchers</span>
                </Sidebar.Item>
              )}
            </Sidebar.Collapse>
          )}

          {/* {loggedInUser?.role?.permissions?.documents?.read && (
            <Sidebar.Item
              className={props.documents}
              as={Link}
              to="/documents"
              icon={IoDocumentAttachOutline}
            >
              <span className="hidden md:block">Documents</span>
            </Sidebar.Item>
          )} */}

          {loggedInUser?.role?.permissions?.property?.read && (
            <Sidebar.Item
              className={props.projects}
              as={Link}
              to="/properties"
              icon={GrProjects}
            >
              <span className="hidden md:block">Properties</span>
            </Sidebar.Item>
          )}

          {loggedInUser?.role?.permissions?.receipt?.read && (
            <Sidebar.Item
              className={props.receipts}
              as={Link}
              to="/receipts"
              icon={TbReport}
            >
              <span className="hidden md:block">E-Receipt</span>
            </Sidebar.Item>
          )}

          {loggedInUser?.role?.permissions?.settings?.read && (
            <Sidebar.Item
              className={props.siteSettings}
              as={Link}
              to="/site-settings"
              icon={MdSettings}
            >
              <span className="hidden md:block">Site Settings</span>
            </Sidebar.Item>
          )}
        </Sidebar.ItemGroup>
      </Sidebar.Items>
    </Sidebar>
  );
}
